import React, { useEffect, useState, useRef } from 'react';
import Select, { components } from 'react-select';
import InputField from '../element/Input';
import { MasterService } from "../../services"; 
import Loader from "../common/loader";
import { toast } from 'react-toastify';


const { ValueContainer, Placeholder } = components;

const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
];

const BMTargetForm = (props) => {
    const [userListData, setuserListData] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setuserListData(props.userListData);
    }, [props.userListData]);

    const CustomValueContainer = ({ children, ...props }) => {
        return (
            <ValueContainer {...props}>
                <Placeholder {...props} isFocused={props.isFocused}>
                    {props.selectProps.placeholder}
                </Placeholder>
                {React.Children.map(children, child =>
                    child && child.type !== Placeholder ? child : null
                )}
            </ValueContainer>
        );
    };

    const handleInputChange = async (event) => { 
        let current_index = (event.target.getAttribute('current_index')-1);
        let user_id       = +event.target.getAttribute('user_id');
        let current_tab   = event.target.getAttribute('current_tab');
        let selectedValue = event.target.value;
        let userList      = [...userListData];
        if(selectedValue && selectedValue > 0){
            if(userList[current_index][current_tab+'_error']){
                delete userList[current_index][current_tab+'_error'];
            }
        }else{
            if(current_tab == 'disb_target'){
                userList[current_index][current_tab+'_error'] = "Amount should be >= 100,00,000"
            }else{
                userList[current_index][current_tab+'_error'] = "Onboard target should be >= 1"
            }
        }
        userList[current_index][current_tab] = selectedValue;
        setuserListData(userList);
    };

    const handleClickSumbit = async (event) => {
        setLoading(true);
        if(validateForm()){
            let userList      = [...userListData];
            await MasterService.post('/sfa/user/set_target', {"userData": JSON.stringify(userList)}).then(function (response) {
                if(response.data.status == 200){
                    toast.success(response.data.message);
                }else{
                    toast.error(response.data.message);
                }
                setLoading(false);
            }).catch(function (response) {
                setLoading(false)
            });
        }else{
            setLoading(false);
            console.log("Some error in Form");
        }
        
    }

    const validateForm = ()=>{
        let userList      = [...userListData];
        let submitForm    = true;
        for(let count = 0; count < userList.length; count ++){
            if(!userList[count].disb_target || userList[count].disb_target < 10000000){
                userList[count].disb_target_error = "Amount should be >= 100,00,000";
                submitForm = false;
            }else{
                delete userList[count].disb_target_error;
                // submitForm = true;
            }
            if(!userList[count].onboard_target || userList[count].onboard_target < 1){
                userList[count].onboard_target_error = "Onboard target should be >= 1";
                submitForm = false;
            }else{
                delete userList[count].onboard_target_error;
                // submitForm = true;
            }
        }
        setuserListData(userList);
        return submitForm;
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="top-heading d-flex">
                <h2>BM Target {months[new Date().getMonth()]}</h2>
            </div>
            <div className='data-table dealer-not-visit-table'>
                <div className='row'>
                <table>
                    <thead>
                        <tr>
                            <th>BM ID</th>
                            <th>BM Name</th>
                            <th>Disb. Target</th>
                            <th>Onboard Target</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userListData?.length > 0 && userListData.map((element, index) => (
                            <tr key={index}>
                                <td>{element.id}</td>
                                <td>{element.name}</td>
                                <td>
                                    <fieldset className="col-md-10">
                                        <div className="material">
                                            <InputField type="number" error={userListData[index].disb_target_error} name="disb_target" label="Disb. Target" placeholder=" " value={userListData[index].disb_target || 0} user_id={element.id} current_index={index+1} current_tab="disb_target" required={true} onChange={handleInputChange} />
                                            <label data-label="Disb. Target*" required={true} className="form-label"></label>
                                        </div>
                                    </fieldset>
                                </td>
                                <td>
                                    <fieldset className="col-md-10">
                                        <div className="material">
                                            <InputField type="number" error={userListData[index].onboard_target_error} label="Onboard Target" placeholder=" " name="onboard_target" value={userListData[index].onboard_target || 0} user_id={element.id} current_index={index+1} current_tab="onboard_target"  onChange={handleInputChange} />
                                            <label data-label="Onboard Target*" className="form-label"></label>
                                        </div>
                                    </fieldset>
                                </td>
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={3}></td>
                            <td>
                                <div className='raw'>
                                    <div className="col-md-3"> </div>
                                    <div className="col-md-9">
                                        <button className='btn-primary' onClick={handleClickSumbit}>
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                </div>
            </div>
        </React.Fragment>
    )
}

export default BMTargetForm;