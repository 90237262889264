import React, { Component } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import ReporteesTable from './ReporteesTable'


class Reportees extends Component {
    constructor(props) {
        super(props);
        console.log(props)

        this.state = {

        };
    }


    render() {

        return (
            <React.Fragment>
                <div className='reportess-tab-outer'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                        <div className='reportess-tabs'>
                            <Nav variant="pills" className="flex-column tab-bg">
                                <div className="tab-list">
                                    <Nav.Item>
                                        <Nav.Link eventKey="first">All (1000)</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="second">ZM (10) </Nav.Link>
                                    </Nav.Item>

                                    <Nav.Item>
                                        <Nav.Link eventKey="third">RBH (10) </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fourth">AM (10) </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fifth">BM (10) </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="sixth">BRO (100) </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="seventh">Agent (100) </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="Eighth">ARO (20) </Nav.Link>
                                    </Nav.Item>
                                </div>

                            </Nav>
                            <fieldset className="search-bx">
                                <div className="material">
                                    <input id="" type="text" placeholder="Search by Name, Email or Mobile" name="RoleName" className="form-input" />
                                    <i className="ic-search"></i>
                                </div>
                            </fieldset>
                        </div>

                        <Tab.Content>
                            <Tab.Pane eventKey="first">
                                <ReporteesTable />
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                            </Tab.Pane>
                            <Tab.Pane eventKey="third">
                            </Tab.Pane>

                        </Tab.Content>

                    </Tab.Container>
                </div>
            </React.Fragment>
        )
    }
}


export default Reportees;