import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import ReactPaginate from 'react-paginate';
import Tooltip from 'react-tooltip-lite';
import InputField from '../common/Input';
import { useNavigate } from 'react-router-dom';
import { Calendra, Calendra2 } from './DatePicker';
import { defaultZone, Zone } from '../../config/constant';
import { getFirstDate } from '../../config/helper';
import DateFormat from 'dateformat';

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const ExecutiveListingTable = (props) => {
    const [zoneValue, setZone] = useState(defaultZone)
    const [executiveData, setExecutiveData] = useState(null);
    const [pageCount, setPageCount] = useState(1)
    const [offset, setOffset] = useState(0);
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [city, setCity] = useState(null);
    const [state, setState] = useState(null);
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenIndex, setTipOpenIndex] = useState(0);
    const [search_text, setSearchText] = useState('')
    const [fromDate, setFromDate] = useState(getFirstDate(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [roleList, setRoleList] = useState([])
    const [roleSelected, setRole] = useState(null);
    const historyLead = useNavigate();

    useEffect(() => {
        setExecutiveData(props.executiveData)
        setPageCount(props.pageCount)
        setCityList(props.cityList)
        setRoleList(props.roleList)
        setStateList(props.stateList)
    }, [props.roleList, props.executiveData, props.pageCount, props.cityList, props.stateList])

    useEffect(() => {
        setOffset(0)
    }, [props.isPageReset])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    useEffect(() => {
        if (props.isPopUpReset) {
            toggleTip(0, 'close', true)
        }
    }, [props.isPopUpReset])

    const handleReset = () => {
        setStateList([])
        setCityList([])
        setCity(null)
        setState(null)
        setExecutiveData(null)
        setPageCount(1)
        setOffset(0)
        setSearchText('')
        setRole(null)
        handleSearch(true)
        setFromDate(getFirstDate(new Date()))
        setToDate(new Date())
    }

    const toggleTip = (index, state, reset) => {
        if (state === 'close') {
            index = 0
        }
        if (reset) {
            setTipOpen(false)
        } else {
            setTipOpen(!tipOpen)
        }

        setTipOpenIndex(index)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? [city.id] : '',
            role_id: roleSelected ? roleSelected.id : '',
            state_id: state ? [state.id] : '',
            status: 1
        }
        props.handleSearchData(search_data)
        props.getExecutiveReporting(selectedPage + 1, search_data, false)
    };

    const handleChange = (event, name) => {
        if (name === "state") {
            setState(event)
            setCity(null)
            props.getCityList(event, true)
        }
        if (name === "city") {
            setCity(event)
        }
        if (name === "zone") {
            setZone(event.id)
        }
        if (name === "from_date") {
            setFromDate(event)
        }
        if (name === "to_date") {
            setToDate(event)
            if (new Date(event).getTime() <= new Date(fromDate).getTime()) {
                setFromDate(null)
            }
        }
        if (name === "role") {
            setRole(event)
        }
    }

    const handleInputChange = async (target_name, type, event) => {
        if (target_name === "search_text") {
            setSearchText(event.target.value)
        }
    }

    const handleSearch = (is_reset) => {
        setOffset(0)
        let search_data = {
            search_text: search_text ? search_text : '',
            city_id: city ? [city.id] : '',
            role_id: roleSelected ? roleSelected.id : '',
            state_id: state ? [state.id] : '',
            page: 1,
            fromDate: fromDate,
            toDate: toDate,
        }
        if (is_reset) {
            search_data = {
                fromDate: "",
                toDate: "",
                city_id: "",
                page: 1,
                role_id:"",
                search_text: "",
                state_id: "",
                status: 1,
                vertical_id: 1
            }
        }
        if (tipOpen) {
            toggleTip(0, 'close', true)
        }
        props.handleSearchData(search_data)
        props.getExecutiveReporting(offset + 1, search_data, true)
    }

    const isDate = (date) => {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    return (
        <React.Fragment>
            <div className="table-heading-filter-no-visit filter-user-visit">
                <div className="filter-right-panel">
                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra
                                placeholder={"Date From"}
                                defaultDate={fromDate}
                                endDate={toDate}
                                handleDateChange={(e) => handleChange(e, 'from_date')}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra2
                                placeholder={"To Date"}
                                startDate={fromDate}
                                disabled={true}
                                defaultDate={toDate}
                                endDate={new Date()}
                                handleDateChange={(e) => handleChange(e, 'to_date')}
                            />
                        </div>
                    </fieldset>
                    {/* <fieldset className="single-select">
                    <div className="material">
                        <Select
                            components={{ ValueContainer: CustomValueContainer }}
                            options={Zone}
                            onChange={(e) => handleChange(e, 'zone')}
                            placeholder="Zone"
                            className="react-select"
                            classNamePrefix="react-select"
                            value={Zone.filter(e => e.id === zoneValue)}
                        />
                    </div>
                </fieldset> */}
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={state}
                                onChange={(e) => handleChange(e, 'state')}
                                options={stateList}
                                placeholder="Select State"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={city}
                                isDisabled={state && state.id ? false : true}
                                onChange={(e) => handleChange(e, 'city')}
                                options={cityList}
                                placeholder="Select City"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={roleSelected}
                                onChange={(e) => handleChange(e, 'role')}
                                options={roleList}
                                placeholder="Select Role"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="search-bx">
                        <div className="material">
                            <InputField type="text" name="search_text" id="search_text" label="Search Executive" placeholder="Search Executive" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                            <i className="ic-search"></i>
                        </div>
                    </fieldset>
                    <div className="btn-submit-reset m-sm-l">
                        <button className='btn-primary' onClick={() => handleSearch(false)}>Search</button>
                        <button className="btn-reset" onClick={() => handleReset()}>Reset</button>
                    </div>
                </div>
            </div>
            <div className='executive-listing data-table user-visit-table'>
                <table>
                    <thead>
                        <tr>
                            <th>Executive Details</th>
                            <th>Role</th>
                            <th>Email ID</th>
                            <th>Reporting Manager</th>
                            <th>Assigned Partner</th>
                            <th>Last Visited Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {executiveData?.length > 0 && executiveData.map((dealers, index) => (
                            <tr>
                                <td className='v-top'>
                                    <span className='d-block'> {dealers.name}</span>
                                    <span>{"(SFA ID - " + dealers.user_id + ")"}</span>
                                </td>
                                <td>{dealers.role_name ? dealers.role_name : "-"}</td>
                                <td title={dealers.email}>{dealers.email ? dealers.email : "-"}</td>
                                <td>{(dealers.reporting_manager_name ? dealers.reporting_manager_name : "") + (dealers.reporting_manager_role_name ? " (" + dealers.reporting_manager_role_name + ")" : "-")}</td>
                                <td>{dealers.dealercount ? dealers.dealercount : "-"}
                                    {dealers.dealercount > 0 && <Tooltip
                                        content={(
                                            <div className="controlled-example tooltip-main">
                                                <div className="tooltip-data">
                                                    <span className="controlled-example_close-button" onClick={() => toggleTip(index, 'close')}><i className="ic-clearclose"></i></span>
                                                    <ul className="user-detail">
                                                        {dealers.assigned_dealer_data?.length && dealers.assigned_dealer_data.map((assing_dealer, a_index) => (
                                                            <li>{assing_dealer.dealer_name + (assing_dealer.id ? " (AMBK-" + assing_dealer.id + ")" : "")}</li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            </div>
                                        )}
                                        className="tootip-outer"
                                        tipContentClassName="tootip-outer"
                                        tagName="span"
                                        isOpen={((index === tipOpenIndex) && tipOpen) ? true : false}
                                        useHover={false}
                                        direction="down"
                                        forceDirection
                                    >
                                        <span onClick={() => toggleTip(index, 'open')} title="" className="tooltio-ic"> <i className='ic-info-icon1'></i>
                                        </span>
                                    </Tooltip>
                                    }
                                </td>
                                <td>{dealers.last_visit_date && isDate(dealers.last_visit_date) ? DateFormat(new Date(dealers.last_visit_date ), 'dd mmm, yyyy') : "-"}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className='pagination-bx' id="dealerUserList">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div >
            </div>
        </React.Fragment>
    )
}
export default ExecutiveListingTable;