import React, { useState, useEffect } from 'react';
import { Tab, Nav, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import secureStorage from '../../config/encrypt';
//import logo from "../../webroot/images/ambak_logo.svg";
import ls, { get, set } from "local-storage";
import { MasterService } from '../../services';
import { toast } from 'react-toastify';
import InputField from '../element/Input';
import Captcha from '../common/Captcha';
import Loader from '../common/loader';
import { EMAIL_VALIDATION } from '../../config/constant';

//Changes for gmail login//
import googleLogo from './../../webroot/images/google-logo.png';
import { API_URL } from './../../config/constant';

const Login = (props) => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, SetErrors] = useState({});
    const [login_detail, SetLoginDetail] = useState({});
    const [reloadCaptcha, setReloadCaptcha] = useState(0);
    const [showOtp, setShowOtp] = useState(false);
    const [otpTimer, setOtpTimer] = useState(60);
    const [otpValue, setOtpValue] = useState('');
    const [inputFieldCaptcha, SetInputFieldCaptch] = useState({ captcha: "" });
    
    //Changes for gmail login//
    const [redirectUrl, setRedirectUrl] = useState(API_URL.GOOGLE_AUTH_URL+window.location.href);
    let requestedUser = (new URLSearchParams(window.location.search)).get("userdata");
    const querydata = requestedUser  && requestedUser!="" ? JSON.parse(requestedUser) : {};
    const [googleEmail, setGoogleEmail] = useState(querydata && querydata.email ? querydata.email : "");
    
    useEffect(() => {
        let footer = document.getElementsByClassName("footer")
        footer[0].classList.add("hidden")
        let logged_data = ls.get('logged_data') 
        if (logged_data && logged_data.token && logged_data.user_data && logged_data.user_data.user_id) {
            navigate("/user-management");
        }
    }, []);
    ls.set('filter_data', {});
    ls.set('current_status', '');

    useEffect(() => {
        if (!otpTimer) return;

        const intervalId = setInterval(() => {
            setOtpTimer(otpTimer - 1);
        }, 1000);

        return () => clearInterval(intervalId);
    }, [otpTimer]);
    
    //Login with gmail changes
    useEffect(() =>{
        if(googleEmail){
            let dataToLogin = {ambak_email: googleEmail};
            MasterService.post('/sfa/user/login-with-gmail',dataToLogin)
            .then(function (loginRes){
                if (loginRes.data.status === 200) {
                    if (loginRes.data.data) {
                        ls.set('logged_data', loginRes.data.data);
                        secureStorage.setItem('loginUserInfo', loginRes.data.data);
                        navigate("/user-management");
                    } else if (loginRes.data.error && loginRes.data.error.length > 0) {
                        loginRes.data.error.forEach(element => {
                            toast.error(element);
                            setLoading(false);
                            navigate("/login");
                        });
                    }
                }else{
                    if (loginRes.data.error && loginRes.data.error.length > 0) {
                        loginRes.data.error.forEach(element => {
                            toast.error(element);
                            setLoading(false)
                        });
                    } else if (loginRes.data.message) {
                        toast.error(loginRes.data.message);
                        setLoading(false)
                    } else {
                        setLoading(false);
                        toast.error("Failed");
                    }
                    navigate("/login");
                    setTimeout(() => {
                        window.location.reload();
                    }, "1000");
                }
            })
            .catch(function (error) {
                toast.error(error);
                setLoading(false);
                navigate("/login");
                setTimeout(() => {
                    window.location.reload();
                }, "1000");
            });
        }
    },[googleEmail])

    const handleLogin = async () => {
        let res = await isValidate()
        if (res) {
            setLoading(true);
            let params = {
                captchaId: login_detail.captchaId,
                captchaVal: login_detail.captchaVal,
                username: login_detail.login_username,
                password: login_detail.password
            }
            MasterService.post('/sfa/user/login-by-email', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data) {

                            handleSendOTP()
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                setReloadCaptcha(reloadCaptcha + 1)
                                resetCaptcha()
                                toast.error(element);
                                setLoading(false)
                            });
                        }
                    } else {
                        setReloadCaptcha(reloadCaptcha + 1)
                        resetCaptcha()
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setLoading(false)
                        } else {
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const resetCaptcha = () => {
        let tempLoginDetail = { ...login_detail };
        tempLoginDetail.captchaVal = ""
        SetLoginDetail(tempLoginDetail)
    }

    const isValidate = async (value) => {
        let tempLoginDetail = { ...login_detail };
        let formIsValid = true;
        let tempErrors = {};
        if (value === "send_otp") {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            } else if (tempLoginDetail["login_username"].match(EMAIL_VALIDATION) == null) {
                tempErrors["login_username"] = 'Email is not valid';
            }
        } else if (value === "validate_otp_captcha") {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            } else if (tempLoginDetail["login_username"].match(EMAIL_VALIDATION) == null) {
                tempErrors["login_username"] = 'Email is not valid';
            }

            if (!tempLoginDetail["otp"]) {
                formIsValid = false;
                tempErrors["otp"] = 'OTP is required';
            }

            if (!tempLoginDetail["captchaId"] || !tempLoginDetail["captchaVal"]) {
                formIsValid = false;
                tempErrors["captcha"] = 'Captcha is required';
            }
        } else if (value === "validate_otp") {
            if (!tempLoginDetail["otp"]) {
                formIsValid = false;
                tempErrors["otp"] = 'OTP is required';
            }
        } else {
            if (!tempLoginDetail["login_username"]) {
                formIsValid = false;
                tempErrors["login_username"] = 'Email is required';
            }

            if (!tempLoginDetail["password"]) {
                formIsValid = false;
                tempErrors["password"] = 'Password is required';
            }

            if (!tempLoginDetail["captchaId"] || !tempLoginDetail["captchaVal"]) {
                formIsValid = false;
                tempErrors["captcha"] = 'Captcha is required';
            }
        }

        if (Object.keys(tempErrors).length > 0) {
            formIsValid = false
        }
        SetErrors(tempErrors);
        return formIsValid;
    }

    const handleInputChange = async (target_name, type, event) => {
        let temp_login_details = { ...login_detail };
        let tempErrors = { ...errors }
        temp_login_details[target_name] = event.target.value;
        tempErrors[target_name] = ""
        SetErrors(tempErrors)
        SetLoginDetail(temp_login_details);
    }

    const captchaFiledHandler = (childData) => {
        let captchaId = childData?.captchaData?.data?.value;

        let tempErrors = { ...errors }
        let temp_login_details = { ...login_detail };
        if (captchaId) {
            tempErrors['captchaErr'] = "";
            temp_login_details['captchaVal'] = childData.captchaVal;
            temp_login_details['captchaId'] = childData.captchaData.data.value;
        } else {
            tempErrors['captchaErr'] = "Please enter valid captcha.";
        }
        SetLoginDetail(temp_login_details);
        SetErrors(tempErrors)
    }

    const handleSendOTP = async () => {
        let res = await isValidate("send_otp")
        if (res) {
            setLoading(true);
            let params = {
                username: login_detail.login_username
            }
            MasterService.post('/sfa/user/send_admin_otp', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data && response.data.data.value) {
                            setShowOtp(true)
                            setOtpValue(response.data.data.value)
                            setLoading(false);
                            setOtpTimer(60)
                            toast.success(response.data.message)
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        }
                    } else {
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setLoading(false)
                        } else {
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const handleSubmit = async (with_captcha) => {
        let res = with_captcha ? await isValidate("validate_otp_captcha") : await isValidate("validate_otp")
        if (res) {
            setLoading(true);
            let params = {
                username: login_detail.login_username,
                otp: otpValue,
                otp_value: login_detail.otp,
                captchaId: login_detail.captchaId,
                captchaVal: login_detail.captchaVal,
                with_captcha: with_captcha
            }
            MasterService.post('/sfa/user/validate_admin_otp', params)
                .then(function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data) {
                            ls.set('logged_data', response.data.data);
                            secureStorage.setItem('loginUserInfo', response.data.data);
                            navigate("/user-management");
                        } else if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setReloadCaptcha(reloadCaptcha + 1)
                                resetCaptcha()
                                setLoading(false)
                            });
                        }
                    } else {
                        setReloadCaptcha(reloadCaptcha + 1)
                        resetCaptcha()
                        if (response.data.error && response.data.error.length > 0) {
                            response.data.error.forEach(element => {
                                toast.error(element);
                                setLoading(false)
                            });
                        } else if (response.data.message) {
                            toast.error(response.data.message);
                            setLoading(false)
                        } else {
                            setLoading(false);
                            toast.error("Failed");
                        }
                    }
                })
                .catch(function (error) {
                    console.log("error", error)
                    toast.error(error);
                    setLoading(false);
                });
        }
    }

    const handleClickTab = () => {
        let tempErrors = { ...errors }
        tempErrors = {}
        SetErrors(tempErrors)
    }

    return (
        <div className="container-fluid">
            {loading ? <Loader /> : null}
            <div className="row">

                <div className='login-main'>

                    <div className="login-outer">
                        <div className="login-right-panel">
                        <div style={{display:"flex",alignItems:"center",justifyContent:"center",marginBottom:"40px"}}>
                        <img src='https://storage.googleapis.com/ambak/logo/ambak_logo.svg' alt='logo' width={230} height={100} />
                        </div>
                            <div className="login-heading">
                                <h2>Login to </h2>
                            </div>
                            <Tab.Container id="" defaultActiveKey="first">
                                <div className="login-tab">
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div className="login-form">
                                                {!showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.login_username ? "material error" : "material"}>
                                                                <InputField type="text" name="login_username" id="login_username" label="Email" placeholder=" " value={login_detail.login_username} onChange={handleInputChange.bind(null, 'login_username', 'text')} required={true} />
                                                                <i className="ic-email1 icn-login"></i>
                                                                <label data-label="Email*" className="form-label"></label>
                                                                {errors.login_username && <span className="erroe-txt">{errors.login_username}</span>}
                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="">
                                                            <div className={errors.password ? "material error" : "material"}>
                                                                <InputField type="password" name="password" id="password" label="Password" placeholder=" " value={login_detail.password} onChange={handleInputChange.bind(null, 'password', 'text')} required={true} />
                                                                <i className="ic-password icn-login"></i>
                                                                <label data-label="Password*" className="form-label"></label>
                                                                {errors.password && <span className="erroe-txt">{errors.password}</span>}
                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="">
                                                            <div className={errors.captcha ? "material error" : "material"}>
                                                                <Captcha className="form-input" onKeyPressCaptcha={captchaFiledHandler} errField={errors} inputField={inputFieldCaptcha} reloadCaptcha={reloadCaptcha} />
                                                                {errors.captcha && <span className="erroe-txt">{errors.captcha}</span>}
                                                            </div>
                                                        </fieldset>
                                                        <button type="button" onClick={handleLogin.bind(this)} className="btn-primary custom-login-button">Log In</button>
                                                        <fieldset className="">
                                                            <div className='logingsign'>
                                                                <div style={{ marginLeft: 120, marginRight: 10, marginTop: 10, marginBottom: 10 }}> Or Sign in with </div>
                                                            </div>
                                                            <a href={redirectUrl} className='logingmail'>
                                                                <img src={googleLogo} alt='logo' width={20} height={20} />
                                                                <div style={{ marginLeft: 5 }}>Sign in with Google</div>
                                                            </a>
                                                        </fieldset>
                                                    </div>
                                                }
                                                {showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <button type="button" className="btn-primary" onClick={() => { handleSubmit(false) }}>Submit</button>
                                                    </div>
                                                }
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <div className="login-form">
                                                <fieldset className="">
                                                    <div className={errors.login_username ? "material error" : "material"}>
                                                        <InputField type="text" name="login_username" id="login_username" label="Email" placeholder=" " value={login_detail.login_username} onChange={handleInputChange.bind(null, 'login_username', 'text')} required={true} />
                                                        <i className="ic-email1 icn-login"></i>
                                                        <label data-label="Email" className="form-label"></label>
                                                        {errors.login_username && <span className="erroe-txt">{errors.login_username}</span>}
                                                    </div>
                                                </fieldset>
                                                {showOtp &&
                                                    <div>
                                                        <fieldset className="">
                                                            <div className={errors.otp ? "material error" : "material"}>
                                                                <InputField type="text" name="otp" id="otp" label="OTP" placeholder=" " value={login_detail.otp} onChange={handleInputChange.bind(null, 'otp', 'text')} required={true} />
                                                                <i className="ic-otp icn-login">
                                                                    <i className="path1"></i>
                                                                    <i className="path2"></i>
                                                                    <i className="path3"></i>
                                                                    <i className="path4"></i>
                                                                </i>
                                                                <label data-label="OTP" className="form-label"></label>
                                                                {errors.otp && <span className="erroe-txt">{errors.otp}</span>}
                                                                {otpTimer > 0 ?
                                                                    <span className="otp-time">{"00:" + (otpTimer > 9 ? otpTimer : "0" + otpTimer)}</span> :
                                                                    <span className="otp-time" style={{ cursor: "pointer" }} onClick={() => { handleSendOTP() }}>Resend</span>
                                                                }

                                                                <div className="otp-success-icon" style={{ display: "none" }} >
                                                                    <i className="ic-check"></i>
                                                                </div>

                                                            </div>
                                                        </fieldset>
                                                        <fieldset className="">
                                                            <div className={errors.captcha ? "material error" : "material"}>
                                                                <Captcha className="form-input" onKeyPressCaptcha={captchaFiledHandler} errField={errors} inputField={inputFieldCaptcha} reloadCaptcha={reloadCaptcha} />
                                                                {errors.captcha && <span className="erroe-txt">{errors.captcha}</span>}
                                                            </div>
                                                        </fieldset>
                                                    </div>
                                                }
                                                {!showOtp && <button type="button" className="btn-primary" onClick={() => { handleSendOTP() }}>Get OTP</button>}
                                                {showOtp && <button type="button" className="btn-primary" onClick={() => { handleSubmit(true) }}>Submit</button>}
                                            </div>
                                        </Tab.Pane>


                                    </Tab.Content>
                                </div>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}


export default Login;