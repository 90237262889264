import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MasterService from '../../services/MasterService';
import Loader from '../common/loader';
import { toast } from 'react-toastify';
var base64 = require('base-64');
const ESIGNLOGVIEW = (props) => {
    const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (props.location && props.location.search && props.location.search !== "") {
      let html_div = document.getElementsByTagName("html")
      html_div[0].innerHTML = ""
      let search_id = props.location.search.replace('?id=', '')
      let temp_logged_user_id = base64.decode(search_id)
      getHTML(temp_logged_user_id)
    } else{
      navigate("/");
    }
  }, []);

  const getHTML = async (temp_logged_user_id) => {
    if (temp_logged_user_id) {
      setLoading(true);
      MasterService.post('/sfa-cron/cron/get_esign_html', { 'id': temp_logged_user_id })
        .then(function (response) {
          if (response.data.status === 200) {
            let html_div = document.getElementsByTagName("html")
            html_div[0].innerHTML = response.data.data.esign_html
            setLoading(false);
          } else {
            response.data.error.forEach(element => {
              toast.error(element);
              setLoading(false)
            });
          }
        })
        .catch(function (response) {
          setLoading(false);
        });
    }
  }
  return (
    <React.Fragment>
      {loading ? <Loader /> : null}
    </React.Fragment>
  )
}
export default ESIGNLOGVIEW;