import React, { Component, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import MasterService from '../../services/MasterService';
import { defaultZone, Zone, VIRTUAL_ADMIN, Business, defaultBusiness, Vertical, defaultVertical, VP_ID, EMAIL_VALIDATION,BUSINESS_TAGGING } from '../../config/constant';
import { DealerService } from '../../services';
import { toast } from 'react-toastify';
import Loader from '../common/loader';
import InputField from '../element/Input';

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const RoleDetails = (props) => {
    const { stateList, user_detail, updateBasicDetail } = props
    const [roleList, setRoleList] = useState([])
    const [CityData, setCityData] = useState(null);
    const [StateData, setStateData] = useState(null);
    const [reportingRoleList, setReportingRoleList] = useState([])
    const [reportingManagerList, setReportingManagerList] = useState([])
    const [role_details, setRoleDetails] = useState({ zone_id: defaultZone,business_line:'home_loan',business_tagging: '' })
    const [role_change, setRoleChange] = useState(false)
    const [errors, SetErrors] = useState(props.location_state && props.location_state.tempErrors ? props.location_state.tempErrors : {});
    const [cityList, setCityList] = useState([])
    const [loading, setLoading] = useState(false);
    const [is_edit, setEdit] = useState(user_detail && user_detail.user_id && user_detail.user_id !== "" ? true : false)
    const [role_validation_error_msg, setRoleValidationErrorMsg] = useState('');
    const historyLead = useNavigate();
    const [reportingSection, setReportingSection] = useState(user_detail.role_details && user_detail.role_details.role === 5 ? true : false);

    const getDropdownButtonLabel = ({ placeholderButtonLabel, value }) => {
        let text = placeholderButtonLabel.split("*")[0]
        if (value && value.some((o) => o.value === "*")) {
            return `All ${text} selected`;
        } else if (value && value.length === 0) {
            return `${placeholderButtonLabel}`;
        } else if (value && value.length) {
            if (text === "State" && value?.length === stateList.length) {
                return `All ${text} selected`;
            } else if (text === "City" && value?.length === cityList.length) {
                return `All ${text} selected`;
            } else {
                return `${value.length} ${text} selected`;
            }
        } else {
            return `${placeholderButtonLabel}`;
        }
    }

    useEffect(() => {
        getRoleList() 
        if (user_detail.role_details) {
            let role_details = user_detail.role_details
            role_details.zone_id = defaultZone
            role_details.employee_id = role_details.employee_id
            setRoleDetails(role_details)
            getCityAndStateData(role_details, true)
            getReportingRole(role_details.role)
            getUsersByRole(role_details.reporting_role)
            if (user_detail.user_id) {
                setEdit(true)
            }
        }
    }, [user_detail])


    useEffect(() => {
        if(reportingRoleList.length)
        handleChange('reporting_role', reportingRoleList[0])
    },[reportingRoleList]);

    useEffect(() => {
        if(role_details.length)
        handleChange('reporting_manager', role_details[0])
    },[role_details]);

    const getCityAndStateData = async (role_details, setData) => {
        await getCityList(role_details.state, setData, role_details)
        let state_data = stateList.filter(e => role_details.state.includes(e.id))
        if (state_data && state_data.length > 0) {
            setStateData(state_data)
        }
    }

    const getCityList = async (state_ids, setData, role_details) => {
        let temp_role_details = role_details
        if (state_ids.length === 0) {
            temp_role_details["city"] = []
            setCityData([])
            setCityList([])
        } else {
            await MasterService.post('/sfa/user/city_list', { state_ids })
                .then(function (response) {
                    if (response.data.status === 200) {
                        var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                        setCityList(city_list);
                        if (setData) {
                            let city_data = city_list.filter(e => role_details.city.includes(e.id))
                            if (city_data && city_data.length > 0) {
                                let currval = city_data.map(v => v.id);
                                temp_role_details["city"] = currval
                                setCityData(city_data)
                            } else {
                                temp_role_details["city"] = []
                                setCityData([])
                            }
                        }
                    }
                })
                .catch(function (response) {
                });
        }
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    let role_list = response.data.data.filter(e => e.name !== VIRTUAL_ADMIN)
                    role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    setRoleList(role_list)
                }
            })
            .catch(function (response) {
            });
    }
    const getUsersByRole = async (role_id) => {
        MasterService.post('/sfa/user/get-users', { role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_manager_list = response.data.data.user_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    reporting_manager_list = reporting_manager_list.filter(e=>e.id != user_detail.user_id)
                    setReportingManagerList(reporting_manager_list)
                }
            })
            .catch(function (response) {
            });
    }
    const getReportingRole = async (role_id) => {
        MasterService.post('/sfa/user/reporting_role_list', { role_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    let reporting_role_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    setReportingRoleList(reporting_role_list)
                }
            })
            .catch(function (response) {
            });
    }
    

    const handleChangeMultiSel = (field_name, field_obj, event) => {
        let currval = [];
        var role_details_temp = { ...role_details };
        let selected_value = []
        if (event.action === "select-option" && event.option.value === "*") {
            selected_value = field_name === "state" ? [{ label: "Select All", value: "*" }, ...stateList] : [{ label: "Select All", value: "*" }, ...cityList]
            currval = field_name === "state" ? stateList.map(v => v.id) : cityList.map(v => v.id);
        } else if (event.action === "deselect-option" && event.option.value === "*") {
            selected_value = []
        } else if (event.action === "deselect-option" && event.option.value !== "*") {
            selected_value = field_obj.filter((o) => o.value !== "*")
            currval = selected_value.map(v => v.id)
        } else if (field_name === "state" && field_obj.length === stateList.length) {
            currval = stateList.map(v => v.id);
            selected_value = [{ label: "Select All", value: "*" }, ...stateList]
        } else if (field_name === "city" && field_obj.length === cityList.length) {
            currval = cityList.map(v => v.id);
            selected_value = [{ label: "Select All", value: "*" }, ...cityList]
        } else {
            currval = field_obj.map(v => v.id);
            selected_value = field_obj
        }
        role_details_temp[field_name] = currval;
        if (field_name === "state") {
            setStateData(selected_value);
            getCityList(currval, true, role_details_temp)
        }
        if (field_name === "city") {
            setCityData(selected_value);
        }
        setRoleDetails(role_details_temp);
    }

    const role_change_validation = async () => {
        let current_role_id = 0;
        if(props.user_detail && props.user_detail.role_details && props.user_detail.role_details.role){
            current_role_id = props.user_detail.role_details.role;
        }
        let params = {
            user_id: user_detail.user_id,
            role_id: role_details.previous_role_id
        }
        let role_status = {}
        role_status.insurance_open_application_status = false;
        role_status.loan_open_application_status = false;
        if(role_change && (current_role_id === 8 || current_role_id === "8")){
                let insurance_open_application = await DealerService.getUserPendingInsuranceApplication({user_list : [params]});
                if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.status === 200) {
                    if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.data) {
                        for (const item of insurance_open_application.data.data) {
                            for (const [k, v] of Object.entries(item['validation'])) {
                                if (v >= 1) {
                                    role_status.insurance_open_application_status = true;
                                    setRoleValidationErrorMsg('There are pending Application for User.')
                                }
                            }
                        }
                    }
                }
                return role_status.insurance_open_application_status;
        }

        if(role_change && (current_role_id === 6 || current_role_id === "6")){

            const loan_lead_params = {
            query: 'query{ get_is_all_approved_docs(api_called_by:sfa user_id : '+params.user_id+' role_id:'+params.role_id+') { is_all_doc_approved lead_ids } }'
            };

            const pending_loan_lead_res = await MasterService.post('/sfa/user/loan_approved_doc', loan_lead_params);
            // console.log("getUserPendingLoanLeads:",pending_loan_lead_res.data.data.data.get_is_all_approved_docs.is_all_doc_approved);
            if (pending_loan_lead_res && pending_loan_lead_res.data && pending_loan_lead_res.data.status === 200) {
                if (pending_loan_lead_res && pending_loan_lead_res.data && pending_loan_lead_res.data.data && pending_loan_lead_res.data.data.data) {
                    if(pending_loan_lead_res.data.data.data.get_is_all_approved_docs.is_all_doc_approved === "false"){
                    role_status.loan_open_application_status = true;
                    setRoleValidationErrorMsg('The Sales Officer needs to get all their pending tasks completed before their role can be updated.')
                    }
                }
            }


            let insurance_open_application = await DealerService.getUserPendingInsuranceApplication({user_list : [params]});

                if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.status === 200) {
                    if (insurance_open_application && insurance_open_application.data && insurance_open_application.data.data) {
                        for (const item of insurance_open_application.data.data) {
                            for (const [k, v] of Object.entries(item['validation'])) {
                                if (v >= 1) {
                                    role_status.loan_open_application_status = true;
                                    setRoleValidationErrorMsg('The Sales Officer needs to get all their pending tasks completed before their role can be updated')
                                }
                            }
                        }
                    }
                }
            return role_status.loan_open_application_status;

        }
    }

    const handleChange = async (name, selectedOption) => {
        let temp_role_details = { ...role_details };
        let tempErrors = { ...errors };
        if (name === 'role') {
            (selectedOption.id === 5)? setReportingSection(true) : setReportingSection(false);
            props.getConfigList(selectedOption.id)
            temp_role_details['reporting_role'] = ""
            temp_role_details['reporting_manager'] = ""
            temp_role_details[name] = selectedOption.id
            setRoleDetails(temp_role_details);
            getReportingRole(selectedOption.id)
            setRoleChange(true)
            // if(selectedOption.id !== 3){
            //     delete tempErrors['business_tagging']
            // }
        } else if (name === 'reporting_role') {
            getUsersByRole(selectedOption.id)
            temp_role_details[name] = selectedOption.id
            setRoleDetails(temp_role_details);
        }else if(name === 'business_tagging'){
            temp_role_details[name] = selectedOption.id;
            setRoleDetails(temp_role_details);
        } else {
            temp_role_details[name] = selectedOption.id
            setRoleDetails(temp_role_details);
        }
        if(selectedOption.id !=''){
            tempErrors[name]='';
        }
        SetErrors(tempErrors);        
    };

    const handleClickCancel = ()=>{
        if(user_detail?.user_id){
            historyLead('/view-user/' + user_detail?.user_id,{ replace: true })
        }        
    }

    const handleClickNext = async () => {
        if (isValidate()) {
            setLoading(true)
            let params = {
                user_id: user_detail.user_id,
                role_id: role_details.previous_role_id
            }
            let ready_to_role_change = false
            let ready_to_role_change_lead = false
            let ready_to_role_change_reportee = false
            let ready_to_role_change_loan = false
            // let ready_to_role_change_progression = false;

            let pending_loan_res
            let pending_lead_res

            let role_validation_status = await role_change_validation();

            let user_logout = false
            if(role_details.role != role_details.previous_role_id || role_details.reporting_manager != role_details.previous_manager_id){
                user_logout = true
            }

            if (role_change && is_edit) {
                await MasterService.post('/sfa/user/get_reportees', { userId: [user_detail.user_id] }).then(async function (response) {
                    if (response.data.status === 200) {
                        if (response.data.data.reportee && response.data.data.reportee.length) {
                            toast.error("Please remove the reportees")
                        } else {
                            ready_to_role_change_reportee = true
                        }
                    }
                })
                if (ready_to_role_change_reportee) {
                    ready_to_role_change = true
                }

                if (role_change) {
                    updateBasicDetail();
                }
                
                if (ready_to_role_change && !role_validation_status) {
                    handleClick(user_logout)
                } else {
                    setRoleChange(false);
                    // props.getConfigList(role_details.previous_role_id)
                }
            } else {
                handleClick(user_logout)
            }
            setLoading(false)

        }
    }

    const handleClick = async (user_logout) => {
        if(is_edit){
            await props.setUserLogout(user_logout)
        }
        await props.handleSave(role_details, 'role_details', false)
        if (is_edit) {
            historyLead('/edit-user/preview_and_confirm?id=' + user_detail.user_id);
        } else {
            historyLead('/add-user/preview_and_confirm');
        }
    }

    const isValidate = () => {
        let tempRoleDetail = { ...role_details };
        let formIsValid = true;
        let tempErrors = {...errors};

        let PHONE_REGEX = "^[6-9][0-9]{9}$";
        if (!tempRoleDetail["username"]) {
            formIsValid = false;
            tempErrors["username"] = 'Username is required';
        }
        if (!tempRoleDetail["mobile_no"]) {
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile is required';
        } else if (!RegExp(PHONE_REGEX).test(tempRoleDetail["mobile_no"])) { 
            formIsValid = false;
            tempErrors["mobile_no"] = 'Mobile number must start with 6,7,8 or 9';
        }  

        if (!tempRoleDetail["email_id"]) {
            formIsValid = false;
            tempErrors["email_id"] = 'Email is required';
        }
        else if (tempRoleDetail["email_id"].match(EMAIL_VALIDATION) == null) {
            tempErrors["email_id"] = 'Email is not valid';
        }

        if (!tempRoleDetail["state"]) {
            formIsValid = false;
            tempErrors["state"] = 'State is required';
        }
        if (!tempRoleDetail["city"]) {
            formIsValid = false;
            tempErrors["city"] = 'City is required';
        }
        if (!tempRoleDetail["role"]) {
            formIsValid = false;
            tempErrors["role"] = 'Role is required';
        }
        if(!tempRoleDetail["business_tagging"]){
            formIsValid = false;
            tempErrors["business_tagging"] = 'Business tagging is required';
        }
        if (!tempRoleDetail["employee_id"]) {
            formIsValid = false;
            tempErrors["employee_id"] = 'Employee is required';
        }else if(isNaN(tempRoleDetail["employee_id"]) || tempRoleDetail["employee_id"].length < 5 || tempRoleDetail["employee_id"].length > 6){
            formIsValid = false;
            tempErrors["employee_id"] = 'Employee Id should be bwtween 5 to 6 digit.';
        }
        if (tempRoleDetail["role"] !== 5 && !tempRoleDetail["reporting_role"]) {
            formIsValid = false;
            tempErrors["reporting_role"] = 'Reporting Role is required';
        }
        if (tempRoleDetail["role"] !== 5 &&  !tempRoleDetail["reporting_manager"]) {
            formIsValid = false;
            tempErrors["reporting_manager"] = 'Reporting Manager is required';
        }
        SetErrors(tempErrors);
        return formIsValid;
    }
    const handleInputChange = async (target_name, type, event) => {        
        let temp_role_details = { ...role_details };
        let tempErrors = { ...errors }   
        if(target_name === 'mobile_no' || target_name === 'employee_id'){
            temp_role_details[target_name] = event.target.value.replace(/\D/g, ''); 
        }else{
            temp_role_details[target_name] = event.target.value; 
        }
        tempErrors[target_name] = "" 
        SetErrors(tempErrors)
        setRoleDetails(temp_role_details);
    }

    const handleInputBlur = async (target_name, type, event)=>{
        let tempErrors = { ...errors };
        if(event.target.value === '' || event.target.value.length <5 || event.target.value.length > 6){
            tempErrors["employee_id"] = 'Employee Id should be between 5 to 6 digit';
            SetErrors(tempErrors)
        }else{
            MasterService.post('/sfa/user/checkEmployeeId', { employee_id: event.target.value })
            .then(function (response) {
                if (response.data.status === 200) {
                    if(response.data.data){
                        tempErrors["employee_id"] = 'Employee Id already exists.';
                    }else{
                        delete tempErrors["employee_id"];
                    }
                    SetErrors(tempErrors);
                }else{
                    tempErrors["employee_id"] = response.data.message;
                    SetErrors(tempErrors);
                }
            })
            .catch(function (response) {
            });
        }
    }
 
    
    return (
        <React.Fragment>
            <div className='role-details-form p-xxl add-user-detail-filed'>
                {loading ? <Loader /> : null}
                <h2>Role Details</h2>

                <h3>
                    Basic Detail 
                </h3>
                <div className='row'>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.username) ? errors.username : ''} name="username" id="username" label="User Name" placeholder=" " value={role_details.username} onChange={handleInputChange.bind(null, 'username', 'text')} />
                            <label data-label="User Name*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.email_id) ? errors.email_id : ''} name="email_id" id="email_id" label="Employee ID" placeholder=" " value={role_details.email_id} onChange={handleInputChange.bind(null, 'email_id', 'text')} />
                            <label data-label="Email ID*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.mobile_no) ? errors.mobile_no : ''} name="mobile_no" maxLength={10} id="mobile_no" label="Employee ID" placeholder=" " value={role_details.mobile_no} onChange={handleInputChange.bind(null, 'mobile_no', 'text')} />
                            <label data-label="Mobile*" className="form-label"></label>
                        </div>
                    </fieldset>
                    <fieldset className="col-md-4">
                        <div className="material">
                            <InputField type="text" error={(errors.employee_id) ? errors.employee_id : ''} name="employee_id" maxLength={6} id="employee_id" label="Employee ID" placeholder=" " value={role_details.employee_id} onBlur={handleInputBlur.bind(null, 'employee_id', 'text')} onChange={handleInputChange.bind(null, 'employee_id', 'text')} />
                            <label data-label="Employee ID*" className="form-label"></label>
                        </div>
                    </fieldset>
                </div>

                <h3>Business Vertical</h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                options={Business}
                                placeholder="Business"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={Business.filter(e => e.id === defaultBusiness)}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                options={Vertical}
                                placeholder="Vertical"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={Vertical.filter(e => e.id === defaultVertical)}
                            />
                        </div>
                    </fieldset> 
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                options={BUSINESS_TAGGING}
                                placeholder="Tagging"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={BUSINESS_TAGGING.filter(e => e.id === role_details.business_tagging)}
                                onChange={handleChange.bind(this, 'business_tagging')}
                            />
                        </div>
                        {
                            (errors && errors.business_tagging) ? <span className="error show absolute">{errors.business_tagging}</span> : ''
                        }
                    </fieldset> 
                </div>

                <h3> Map Region</h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                options={Zone}
                                placeholder="Zone"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={Zone.filter(e => e.id === role_details.zone_id)}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="multiselect-dropDown col-md-4">
                        <div className="material">

                            <ReactMultiSelectCheckboxes
                                isMulti
                                options={[{ label: "Select All", value: "*" }, ...stateList]}
                                placeholderButtonLabel="State*"
                                iconAfter="false"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={StateData?.length === stateList?.length ? [{ label: "Select All", value: "*" }, ...stateList] : StateData}
                                onChange={handleChangeMultiSel.bind(this, "state")}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            />
                            {(StateData && StateData.length > 0) ? <label data-label="State*" className="form-label date-label"></label> : ''}
                            {
                                (errors && errors.state) ? <span className="error show">{errors.state}</span> : ''
                            }
                        </div>
                    </fieldset>
                    <fieldset className="multiselect-dropDown col-md-4">
                        <div className="material">
                            <ReactMultiSelectCheckboxes
                                isMulti
                                options={[{ label: "Select All", value: "*" }, ...cityList]}
                                placeholderButtonLabel="City*"
                                iconAfter="false"
                                getDropdownButtonLabel={getDropdownButtonLabel}
                                value={CityData?.length === cityList?.length ? [ ...cityList] : CityData}
                                onChange={handleChangeMultiSel.bind(this, "city")}
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                            />
                            {(CityData && CityData.length > 0) ? <label data-label="City*" className="form-label date-label"></label> : ''}
                            {
                                (errors && errors.city) ? <span className="error show">{errors.city}</span> : ''
                            }
                        </div>
                    </fieldset>
                </div>
                <h3>Role & Reporting</h3>
                <div className='row'>
                    <fieldset className="single-select col-md-4">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                options={roleList}
                                placeholder="Role*"
                                className="react-select"
                                classNamePrefix="react-select"
                                value={roleList.filter(e => e.id === role_details.role)}
                                onChange={handleChange.bind(this, 'role')}
                            />
                        </div>
                        {
                            (errors && errors.role) ? <span className="error show absolute">{errors.role}</span> : ''
                        }
                        {
                            (role_validation_error_msg) ? <span className="error show absolute">{role_validation_error_msg}</span> : ''
                        }
                    </fieldset>
                    {
                        (!reportingSection) ? 
                        <>
                            <fieldset className="single-select col-md-4">
                                <div className="material">
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        options={reportingRoleList}
                                        placeholder="Reporting Role*"
                                        className="react-select"
                                        classNamePrefix="react-select"
                                        value={reportingRoleList.filter(e => e.id === role_details.reporting_role)}
                                        isDisabled={role_details.role && role_details.role !== "" ? false : true}
                                        onChange={handleChange.bind(this, 'reporting_role')}
                                    />
                                </div>
                                {
                                    (errors && errors.reporting_role) ? <span className="error show absolute">{errors.reporting_role}</span> : ''
                                }
                            </fieldset>
                            <fieldset className="single-select col-md-4">
                                <div className="material">
                                    <Select components={{ ValueContainer: CustomValueContainer }}
                                        options={reportingManagerList}
                                        value={reportingManagerList.filter(({ id }) => id == role_details.reporting_manager)}
                                        getOptionLabel={({ label }) => label}
                                        getOptionValue={({ id }) => id}
                                        onChange={handleChange.bind(this, 'reporting_manager')}
                                        placeholder="Reporting Manager*"
                                        className="react-select"
                                        isDisabled={role_details.reporting_role && role_details.reporting_role !== "" ? false : true}
                                        classNamePrefix="react-select"
                                    />
                                </div>
                                {
                                    (errors && errors.reporting_manager) ? <span className="error show absolute">{errors.reporting_manager}</span> : ''
                                }
                            </fieldset>
                        </>
                    : null }
                </div>
                <div className='br-top-flex'>
                    {is_edit ? <div className="t-right">
                        <button className='btn-line m-xs-l' onClick={handleClickCancel.bind(this)}>
                            Cancel
                        </button>
                    </div> : null}
                    <div className="t-right">
                        <button className='btn-primary' onClick={handleClickNext.bind(this)}>
                           Next
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default RoleDetails;