import React, { useEffect, useState } from 'react';
import { Tab, Nav } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { MasterService } from '../../services';
import DealerDetail from './DealerDetail';
import ExecutiveDetail from './ExecutiveDetail';
import MapFilter from "./MapTopFilter"
import SalesMappedDealer from './SalesMappedDealer';
import SalesMappedUser from "./SalesMappedUser";
import ls, { get, set } from "local-storage";

const MapDealerManagement = (props) => {
    const [user_detail, setUserDetail] = useState(null);
    const [assigned_dealer, setAssignedDealer] = useState(null);
    const [unAssigned_dealer, setUnAssignedDealer] = useState(null);
    const [assigned_executive, setAssignedExecutive] = useState(null);
    const [unAssigned_Executive, setUnAssignedExecutive] = useState(null);
    const [loading, setLoading] = useState(false);
    const [filter_reset, setFilterReset] = useState(false);
    const [search_reset, setSearchReset] = useState(false);
    const [show_dealer_list, setShowDealerList] = useState(false);
    const [show_executive_list, setShowExecutiveList] = useState(false);
    const [listing_state, setListingState] = useState();
    const [activeTab, setActiveTab] = useState(props.location?.state?.type ? props.location.state.type : 'dealer');

    useEffect(() => {
        if (props.location?.state?.type) {
            setActiveTab(props.location.state.type)
        }
        setListingState(props.location?.state)
    }, [props.location?.state])

    const handleChangeDealer = async (params) => {
        setLoading(true)
        let user_detail = await handleDealerDetail(params.dealer_id)
        params.city_id = user_detail.city_id
        await handleAssignedExecutive(params)
        setShowExecutiveList(true)
        setLoading(false)
        setSearchReset(search_reset + 1)
    }

    const clearDealersDetails = () => {
        setUserDetail(null);
        setShowExecutiveList(false);
    }

    const clearExecutiveDetails = () => {
        setUserDetail(null);
        setShowDealerList(false)
    }

    const handleChangeExecutive = async (params) => {
        setLoading(true)
        let user_detail = await handleUserDetail(params.user_id)
        params.city_id = user_detail.city_id
        await handleAssignedDealer(params)
        setShowDealerList(true)
        setLoading(false)
        setSearchReset(search_reset + 1)
    }

    const handleUserDetail = async (user_id) => {
        let user_detail = {}
        await MasterService.post('/sfa/dealer/getUserDetailsById', { user_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    if (Object.keys(response.data.data.user_detail).length) {
                        setUserDetail(response.data.data.user_detail)
                        user_detail = response.data.data.user_detail
                    } else {
                        toast.error("User Not Found");
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response.data.message);
            });
        return user_detail
    }

    const handleDealerDetail = async (dealer_id) => {
        let user_detail = {}
        await MasterService.post('/sfa/dealer/getDealerDetailsById', { dealer_id })
            .then(function (response) {
                if (response.data.status === 200) {
                    if (Object.keys(response.data.data.user_detail).length) {
                        setUserDetail(response.data.data.user_detail)
                        user_detail = response.data.data.user_detail
                    } else {
                        toast.error("User Not Found");
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response.data.message);
            });
        return user_detail
    }

    const handleAssignedDealer = async (params) => {
        if (!params) {
            params.user_id = user_detail.id
        }
        params.type = "assigned"
        setAssignedDealer(null)
        await MasterService.post('/sfa/dealer/getDealerList', params)
            .then(async function (response) {
                if (response.data.status === 200) {
                    setAssignedDealer(response.data.data.dealer_data);
                    await handleUnAssignedDealer(params, response.data.data.dealer_data);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response.data.message);
            });
    }

    const handleUnAssignedDealer = async (params, temp_assigned_dealer) => {
        if (!params.user_id) {
            params.user_id = user_detail.id
        }
        if (!temp_assigned_dealer) {
            temp_assigned_dealer = assigned_dealer
        } else {
            setAssignedDealer(temp_assigned_dealer)
        }
        /*if (!params.city_id) {
            params.city_id = user_detail.city_id
        }*/
        params.type = "all"
        setUnAssignedDealer(null)
        await MasterService.post('/sfa/dealer/getDealerList', params)
            .then(async function (response) {
                if (response.data.status === 200) {
                    let result = response.data.data.dealer_data.filter(o1 => !temp_assigned_dealer.some(o2 => o1.dealer_id === o2.dealer_id));
                    result = await sort(result, 'name')
                    setUnAssignedDealer(result)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response);
            });

    }

    const handleAssignedExecutive = async (params) => {

        if (!params.dealer_id) {
            params.dealer_id = user_detail.dealer_id
        }
        params.type = "assigned"
        setAssignedExecutive(null)
        await MasterService.post('/sfa/dealer/getExecutiveList', params)
            .then(async function (response) {
                if (response.data.status === 200) {
                    setAssignedExecutive(response.data.data.executive_data)
                    await handleUnAssignedExecutive(params, response.data.data.executive_data)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response);
            });
    }

    const handleUnAssignedExecutive = async (params, temp_assigned_executive) => {
        if (!params.dealer_id) {
            params.dealer_id = user_detail.dealer_id
        }
        if (!params.city_id) {
            params.city_id = user_detail.city_id
        }
        if (!temp_assigned_executive) {
            temp_assigned_executive = assigned_executive
        } else {
            setAssignedExecutive(temp_assigned_executive)
        }
        params.type = "all"
        setUnAssignedExecutive(null)
        await MasterService.post('/sfa/dealer/getExecutiveList', params)
            .then(async function (response) {
                if (response.data.status === 200) {
                    let result = response.data.data.executive_data.filter(o1 => !temp_assigned_executive.some(o2 => o1.user_id === o2.user_id));
                    result = await sort(result, 'name')
                    setUnAssignedExecutive(result)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response.data.message);
            });
    }

    const sort = (array, key) => {
        const sortedList = array.sort((a, b) => a[key].localeCompare(b[key], 'en-US', {caseFirst: 'upper'}));
        return sortedList
    }

    const updateAssignExecutive = async (assigned_user_id,isConfirmed   ) => {
        let params = {
            assigned_user_id,
            dealer_id: user_detail.dealer_id,
            dealer_name: user_detail.dealer_name,
            is_confirmed: isConfirmed
        }
        let logged_data = ls.get('logged_data')
        if (logged_data && logged_data.token && logged_data.user_data && logged_data.user_data.user_id) {
            params.logged_user_id = logged_data.user_data.user_id ? parseInt(logged_data.user_data.user_id) : 0
        }
        setLoading(true)
        await MasterService.post('/sfa/dealer/updateAssignExecutive', params)
            .then(async function (response) {
                if (response.data.status === 200) {
                    let params_executive = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, dealer_id: user_detail.dealer_id }
                    await handleAssignedExecutive(params_executive)
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response.data.message);
            });
        setLoading(false)
    }

    const updateAssignDealer = async (assigned_dealer_id, isConfirmed) => {
        let params = {
            assigned_dealer_id,
            user_id: user_detail.id,
            is_confirmed: isConfirmed
        }
        let logged_data = ls.get('logged_data')
        if (logged_data && logged_data.token && logged_data.user_data && logged_data.user_data.user_id) {
            params.logged_user_id = logged_data.user_data.user_id ? parseInt(logged_data.user_data.user_id) : 0
        }
        setLoading(true)
        await MasterService.post('/sfa/dealer/updateAssignDealer', params)
            .then(async function (response) {
                if (response.data.status === 200) {
                    let params_dealer = { "vertical_id": 1, "page": 1, status: '1', per_page: 50, user_id: user_detail.id }
                    await handleAssignedDealer(params_dealer)
                    toast.success(response.data.message)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                toast.error(response.data.message);
            });
        setLoading(false)
    }

    const handleTabChange = (value) => {
        setActiveTab(value)
        handleReset()
    }

    const handleReset = () => {
        setUserDetail(null)
        setAssignedDealer(null)
        setUnAssignedDealer(null)
        setFilterReset(!filter_reset)
        setShowDealerList(false)
        setShowExecutiveList(false)
    }

    return (
        <React.Fragment>
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>Map Partners / Executive</h1>
                    </div>
                    <div className="dealer-user-mapping-tab-outer map-dealer-main">
                        <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab}>
                            <div className="dealer-user-mapping-tabs">
                                <Nav variant="pills" className="flex-column tab-line">
                                    <div className="tab-list">
                                        <Nav.Item>
                                            <Nav.Link eventKey="dealer" onClick={() => handleTabChange('dealer')}>Map Partner</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="executive" onClick={() => handleTabChange('executive')}> Map Executive</Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>

                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="dealer">
                                    <div className='map-dealer-outer'>
                                        <h2>Select Partners</h2>
                                        <MapFilter
                                            type='dealer'
                                            filter_reset={filter_reset}
                                            listing_state={listing_state}
                                            handleChangeDealer={handleChangeDealer}
                                            handleChangeExecutive={handleChangeExecutive}
                                            clearDealersDetails={clearDealersDetails}
                                            activeTab={activeTab}
                                        />
                                        {user_detail &&
                                            <DealerDetail user_detail={user_detail} loading={loading} />
                                        }
                                        <div className='sales-mapped-user-outer'>
                                            {show_executive_list && Object.keys(user_detail).length > 0 &&
                                                <SalesMappedUser
                                                    handleUnAssignedExecutive={handleUnAssignedExecutive}
                                                    updateAssignExecutive={updateAssignExecutive}
                                                    assigned_executive={assigned_executive}
                                                    unAssigned_Executive={unAssigned_Executive}
                                                    search_reset={search_reset}
                                                />
                                            }
                                        </div>
                                    </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="executive">
                                    <div className='map-dealer-outer'>
                                        <h2>Select Executive</h2>
                                        <MapFilter
                                            type='executive'
                                            filter_reset={filter_reset}
                                            listing_state={listing_state}
                                            handleChangeDealer={handleChangeDealer}
                                            handleChangeExecutive={handleChangeExecutive}
                                            clearExecutiveDetails={clearExecutiveDetails}
                                            activeTab={activeTab}
                                        />
                                        {user_detail &&
                                            <ExecutiveDetail user_detail={user_detail} loading={loading} />
                                        }
                                        <div className='sales-mapped-user-outer'>
                                            {show_dealer_list && Object.keys(user_detail).length > 0 &&
                                                <SalesMappedDealer
                                                    handleUnAssignedDealer={handleUnAssignedDealer}
                                                    updateAssignDealer={updateAssignDealer}
                                                    assigned_dealer={assigned_dealer || []}
                                                    unAssigned_dealer={unAssigned_dealer || []}
                                                    search_reset={search_reset}
                                                />
                                            }
                                        </div>
                                    </div>
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>


            </div>
        </React.Fragment>
    )
}
export default MapDealerManagement;