import React, { useEffect, useState, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
import Tooltip from 'react-tooltip-lite';


const AttendancelistingTable = (props) => {
    const csvLink = useRef();
    const [csvData, setCsvData] = useState([]);
    const [pageCount, setPageCount] = useState(1)
    const [attendanceData, setAttendanceData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [remarkTip, setRemarkTip] = useState({
        index:0,
        status:false,
    });

    useEffect(() => {
        setAttendanceData(props.attendanceData)
        setPageCount(props.pageCount)
    }, [props.attendanceData, props.pageCount])

    useEffect(()=> {
        setOffset(0)
    }, [props.isPageReset])

    const downloadData = async () => {
        let csv_data = await props.getExportData();
        setCsvData(csv_data)
        csvLink.current.link.click();
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        props.getAttendanceData(selectedPage + 1, {})
    };

    return (
        <React.Fragment>

            <div className="top-heading d-flex">
                <h2>Regional Manager Listing</h2>
                <button className="btn-line">
                    <i className="ic-export m-xs-r"></i>
                    <span onClick={() => downloadData()} >Export Data</span>
                    <CSVLink
                        data={csvData}
                        filename={`attendance-list-${Math.round(+new Date() / 1000)}.csv`}
                        data-interception='off'
                        ref={csvLink}
                    >
                    </CSVLink>
                </button>
            </div>
            <div className='data-table dealer-not-visit-table'>
                <table>
                    <thead>
                        <tr>
                            <th>SFA ID</th>
                            <th>Regional Manager</th>
                            <th>Date</th>
                            <th>Attendance Status</th>
                            <th>Zonal Head</th>
                            <th>Remarks</th>
                        </tr>
                    </thead>
                    <tbody>
                        {attendanceData?.length > 0 && attendanceData.map((element, index) => (
                            <tr>
                                <td><span className='d-block'>{element["SFA ID"] ? element["SFA ID"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["Sales Officer"] ? element["Sales Officer"] : "-"}</span></td>
                                <td><span className='d-block'>{element["Date"] ? element["Date"] : "-"}</span></td>
                                <td><span className='d-block'>{element["Status"] ? element["Status"] : "-"}</span></td>
                                <td><span className='d-block wrap-cell'>{element["Sales Manager"] ? element["Sales Manager"] : "-"}</span></td>
                                <td>{element.remarks && element.remarks != "null" && element.remarks.length > 16 ?
                                    <span className='d-block wrap-cell'>{element.remarks.substring(0, 16)}
                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button"
                                                                onClick={() => setRemarkTip({status:false,index:index})}
                                                            ><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail wrap-cell">
                                                                {element.remarks}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                className="tootip-outer"
                                                tipContentClassName="tootip-outer"
                                                tagName="span"
                                                isOpen={(index === remarkTip.index) && remarkTip.status ? true : false}
                                                useHover={false}
                                                direction="down"
                                                forceDirection
                                            >
                                                <span onClick={() => setRemarkTip({status:true,index:index})} title="">...<span className='custom-anchor-tag'>more</span></span>
                                            </Tooltip>
                                        </span> : <span className='d-block wrap-cell'>{element.remarks && element.remarks != "null" ? element.remarks : "-"}</span>
                                    }
                                </td>
                            </tr>
                            
                        ))}

                    </tbody>
                </table>
                <div className='pagination-bx' id="dealerUserList">
                    <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pageCount}
                        marginPagesDisplayed={2}
                        forcePage={offset}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"} />
                </div >
            </div>

        </React.Fragment>
    )
}

export default AttendancelistingTable;