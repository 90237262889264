import React, { Component, useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { defaultZone, EXECUTIVE_ROLES, VISIT_EXECUTIVE_ROLES, Zone } from '../../config/constant';
import { MasterService } from '../../services';
import { Calendra, Calendra2 } from './DatePicker'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import { getFirstDate, getPreviousDate, getTomorrowDate, sort } from '../../config/helper';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const VisitTypeList = [
    { value: '', label: 'All Visit' },
    { value: 'D', label: 'Partner' },
    { value: 'C', label: 'Customer' },
    { value: 'O', label: 'Office' },
    { value: 'F', label: 'Financier' },
    { value: 'NP', label: 'New Partner' }

]
const statusOptions = [
    { value: 'All', label: 'All' },
    { value: '1', label: 'Completed' },
    { value: '0', label: 'Pending' },
]

const VisitReportTopFilter = (props) => {
    const [zoneValue, setZone] = useState(defaultZone)
    const [roleList, setRoleList] = useState([])
    const [roleSelected, setRole] = useState(null);
    const [roleNameList, setRoleNameList] = useState(null)
    const [roleName, setRoleName] = useState(null);
    const [fromDate, setFromDate] = useState(getFirstDate(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [visitType, setVisitType] = useState(null);
    const [statusType, setStatusType] = useState(null);
    const [executiveData, setExecutiveData] = useState(null);
    const [executive, setExecutive] = useState(null);
    const [allUserIds, setAllUserIds] = useState([]);
    useEffect(()=>{
        getExecutiveList();

    },[roleName])

    useEffect(() => {
        getRoleList()
    }, [])

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    useEffect(() => {
            getRoleName()
    }, [roleSelected])

    const getExecutiveList = async () => {
        let searchData = {
            role_id: [roleSelected ? roleSelected.id : 1],
            user_id: [roleName ? roleName.id: 1]
        }
        MasterService.post('/sfa/dealer/executiveList', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let executive_list = response.data.data.executive_data.map(elm => ({ id: elm.id, label: elm.name, value:elm.name,user_id:elm.id,roleId:elm.roleId }));
                    setExecutiveData(executive_list);
                    setAllUserIds(executive_list.map(elm => elm.user_id));
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    if (props.type === "executive") {
                        let role_list = response.data.data.filter(e => VISIT_EXECUTIVE_ROLES.includes(e.id))
                        role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                        setRoleList(role_list)
                    }
                }
            })
            .catch(function (response) {
            });
    }

    const handleChange = (event, name) => {
        if (name === "zone") {
            setZone(event.id)
        }
        if (name === "from_date") {
            setFromDate(event)
        }
        if (name === "to_date") {
            setToDate(event)
            if (new Date(event).getTime() <= new Date(fromDate).getTime()) {
                setFromDate(null)
            }
        }
        if (name === "role") {
            setRole(event)
            setRoleName(null)
            setExecutive(null)
        }
        if (name === "roleName") {
            setRoleName(event)
            setExecutive(null)
        }
        if (name === "visit_type") {
            setVisitType(event)
        }
        if (name === 'status') {
            setStatusType(event)
        }
        if (name === 'executive') {
            setExecutive(event)
        }
    };

    const getRoleName = async () => {
        let searchData = {
            search_text: '',
            role_id: roleSelected ? roleSelected.id : '',
        }
        await MasterService.post('/sfa/dealer/executiveListByCities', searchData)
            .then(function (response) {
                if (response.data.status === 200) {
                    let executive_names = response.data.data.executive_data.map((e) => ({
                        label: e.name,
                        id: e.id,
                        value: e.id,
                        user_id: e.id
                    }))
                    executive_names = sort(executive_names, 'label')
                    setRoleNameList(executive_names);
                } else {
                    if (response.data.error && response.data.error.length > 0) {
                        response.data.error.forEach(element => {
                            toast.error(element);
                        });
                    } else {
                        toast.error(response.data.message);
                    }
                }
            })
            .catch(function (response) {
            });
    }


    const handleSearch = (is_reset) => {
        if (props.type === "executive") {
            if (is_reset) {
                let params = {
                    from_date: getFirstDate(new Date()),
                    to_date: new Date(),
                    role_id: '',
                    zone_id: zoneValue,
                    visit_type: '',
                    visit_status: '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    userId: ''
                }
                props.getVisitData(1, params, true)
            } else {
                let params = {
                    from_date: fromDate,
                    to_date: toDate,
                    role_id: executive && executive.roleId ? executive.roleId :'',
                    zone_id: zoneValue,
                    visit_type: visitType && visitType.value ? visitType.value : '',
                    visit_status: statusType && statusType.value ? statusType.value : '1',
                    units_id: '2',
                    vertical_id: 1,
                    page: 1,
                    sortby: 'sort_visit_date',
                    order: 'DESC',
                    by_user: '',
                    // userId: executive && executive.id ? executive.id : ''
                    userId: executive && executive.id ? executive.id : roleName ? allUserIds : ''
                }
                props.getVisitData(1, params, true)
            }
        }
    }

    const handleReset = () => {
        setZone(defaultZone)
        setFromDate(getFirstDate(new Date()))
        setToDate(new Date())
        setRole(null)
        setRoleName(null)
        setVisitType(null)
        setStatusType(null)
        setExecutive(null)
        setExecutiveData(null)
        handleSearch(true)
    }

    return (
        <React.Fragment>
            <div className="visit-report-filter">
                <fieldset className="rangepicker-calendra">
                    <div className="material">
                        <Calendra
                            placeholder={"Date From"}
                            defaultDate={fromDate}
                            endDate={toDate}
                            handleDateChange={(e) => handleChange(e, 'from_date')}
                        />
                    </div>
                </fieldset>
                <fieldset className="rangepicker-calendra">
                    <div className="material">
                        <Calendra2
                            placeholder={"To Date"}
                            startDate={fromDate}
                            defaultDate={toDate}
                            endDate={new Date()}
                            handleDateChange={(e) => handleChange(e, 'to_date')}
                        />
                    </div>
                </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={roleSelected}
                                onChange={(e) => handleChange(e, 'role')}
                                options={roleList}
                                placeholder="Select Role"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                value={roleName}
                                isDisabled={!roleSelected}
                                onChange={(e) => handleChange(e, 'roleName')}
                                options={roleNameList}
                                placeholder="Role List"
                                className="react-select"
                                classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
                 <fieldset className="single-select">
                    <div className="material">
                                 <Select components={{ ValueContainer: CustomValueContainer }}
                                    value={executive}
                                    isDisabled={!roleName}
                                    onChange={(e) => handleChange(e, 'executive')}
                                    options={executiveData}
                                    placeholder="Executive List"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                    </div>
                </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select components={{ ValueContainer: CustomValueContainer }}
                                value={visitType}
                                onChange={(e) => handleChange(e, 'visit_type')}
                                options={VisitTypeList}
                                placeholder="Visit Type"
                                className="react-select"
                                classNamePrefix="react-select"
                            />
                        </div>
                    </fieldset>
                <fieldset className="single-select">
                    <div className="material">
                        <Select components={{ ValueContainer: CustomValueContainer }}
                            value={statusType}
                            onChange={(e) => handleChange(e, 'status')}
                            options={statusOptions}
                            placeholder="Status"
                            className="react-select"
                            classNamePrefix="react-select"
                        />
                    </div>
                </fieldset>
                <div className="btn-submit-reset">
                    <button type="submit" className="btn-primary" onClick={() => { handleSearch(false) }}>Search</button>
                    <button className="btn-reset" onClick={() => handleReset()}>Reset</button>
                </div>
            </div>
        </React.Fragment>
    )
    // }
}
export default VisitReportTopFilter;