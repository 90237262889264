import React, { useEffect, useState } from "react";
import { Tab } from 'react-bootstrap';
import BMTargetTopFilter from "./BMTargetTopFilter"
import { MasterService } from "../../services";
import Loader from "../common/loader";
import BMTargetForm from './BMTargetForm';
import { getFirstDate } from '../../config/helper';
const BMTarget = (props) => {
    const [loading, setLoading] = useState(false);
    const [isReset, setReset]   = useState(0);
    const [userListData, setuserListData] = useState([]);

    useEffect(() => {
        getActiveRmList();
    }, []);
    const getActiveRmList = async () => {
        let result = []
        setLoading(true);
        await MasterService.post('/sfa/user/get-users', {"role_id": 3}).then(function (response) {
            if (response && response.data && response.data.status === 200 &&  response.data.data && response.data.data.user_list) {
                result = response.data.data.user_list;
                getUserTarget(result);
            }else{
                setuserListData([]);
                setLoading(false)
            }
            setLoading(false);
        }).catch(function (response) {
            setLoading(false)
        });
        return result;
    }

    const getUserTarget = async (userList)=>{
        let result = []
        setLoading(true);
        await MasterService.post('/sfa/user/get_target').then(function (response) {
            if (response && response.data && response.data.status === 200 &&  response.data.data) {
                result = response.data.data;
                setLoading(false);
                if(result.length > 0){
                    for(let count = 0; count < userList.length; count ++){
                        let userData = userList[count];
                        let userExits = result.find(element => element.user_id == userData.id);
                        if(userExits){
                            userList[count].disb_target = userExits.disbursed_target;
                            userList[count].onboard_target = userExits.partner_taraget;
                        }else{
                            userList[count].disb_target = "";
                            userList[count].onboard_target = "";
                        }
                    }
                    setuserListData(userList); 
                    return result;
                }else{
                    setuserListData(userList);
                }
            }else{
                setuserListData(userList);
            }
        }).catch(function (response) {
            setLoading(false)
        });
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>BM Target</h1>
                    </div>
                    {/*<div className='visit-filter-outer'>
                        <BMTargetTopFilter isReset={isReset} />
                    </div>*/}
                    <div className="dealer-user-mapping-tab-outer no-visit-report-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <BMTargetForm
                                        loading={loading}
                                        userListData = {userListData}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default BMTarget;