import React, { useEffect, useState } from 'react';
import Select, { components } from 'react-select';
import InputField from '../common/Input';
import { Calendra, Calendra2 } from './DatePicker'
import { getFirstDate } from '../../config/helper';
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, child =>
                child && child.type !== Placeholder ? child : null
            )}
        </ValueContainer>
    );
};

const statusOptions = [
    { id: '1', label: 'Mapped' },
    { id: '0', label: 'UnMapped' },
]

const UserMappingHistoryTopFilter = (props) => {
    const {userId} = props;
    const [fromDate, setFromDate] = useState(getFirstDate(new Date()));
    const [toDate, setToDate] = useState(new Date());
    const [selectedStatus, setSelectedStatus] = useState("")
    const [search_text, setSearchText] = useState('')

    useEffect(() => {
        if (props.isReset) {
            handleReset()
        }
    }, [props.isReset])

    const handleInputChange = async (target_name, type, event) => {
        if (target_name === "search_text") {
            setSearchText(event.target.value)
        }
    }

    const handleChange = (event, name) => {

        if (name === "from_date") {
            setFromDate(event)
        }
        if (name === "to_date") {
            setToDate(event)
            if (new Date(event).getTime() <= new Date(fromDate).getTime()) {
                setFromDate(null)
            }
        }

        if (name === 'status') {
            setSelectedStatus(event.id)
        }
    };

    const handleSearch = (is_reset) => {
        if (is_reset) {
            let params = {
                user_id:userId,
                page: 1,
                mapping_status:""
            }
            props.setSearch(1, params, true)
        } else {
            let params = {
                from_date: fromDate,
                to_date: toDate,
                mapping_status: selectedStatus,
                search_text:search_text,
                page: 1,
                user_id:userId,
            }

            props.setSearch(1, params, true);
        }
    }

    const handleReset = () => {
        setFromDate(getFirstDate(new Date()));
        setToDate(new Date());
        setSelectedStatus('');
        setSearchText('');
        handleSearch(true)
    }

   

    return (
        <React.Fragment>
                <div className="visit-report-filter">
                <div className="d-block w-100 m-md-b"><h2>Filter by</h2></div>
                <fieldset className="search-bx">
                        <div className="material">
                            <div className="material">
                                <InputField type="text" name="search_text" id="search_text" label="Search By Name" placeholder="Search By Name Or Id" value={search_text} onChange={handleInputChange.bind(null, 'search_text', 'text')} />
                                <i className="ic-search"></i>
                            </div>
                        </div>
                    </fieldset>
                    <fieldset className="single-select">
                        <div className="material">
                            <Select
                                components={{ ValueContainer: CustomValueContainer }}
                                value={statusOptions.filter(e => e.id === selectedStatus)}
                                onChange={(e) => handleChange(e, 'status')}
                                options={statusOptions}
                                placeholder="Status"
                                className="react-select"
                                classNamePrefix="react-select"
                                getOptionLabel={({ label }) => label}
                                getOptionValue={({ id }) => id}
                                // menuIsOpen={true}
                            />
                        </div>
                    </fieldset>

                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra
                                placeholder={"Date From"}
                                defaultDate={fromDate}
                                endDate={toDate}
                                handleDateChange={(e) => handleChange(e, 'from_date')}
                            />
                        </div>
                    </fieldset>
                    <fieldset className="rangepicker-calendra">
                        <div className="material">
                            <Calendra2
                                placeholder={"To Date"}
                                startDate={fromDate}
                                defaultDate={toDate}
                                endDate={new Date()}
                                handleDateChange={(e) => handleChange(e, 'to_date')}
                            />
                        </div>
                    </fieldset>

                    <div className="btn-submit-reset">
                        <button type="submit" className="btn-primary" onClick={() => { handleSearch(false) }}>Update</button>
                        <button className="btn-reset" onClick={() => handleReset()}>Reset</button>
                    </div>
                </div>

        </React.Fragment>
    )
}
export default UserMappingHistoryTopFilter;