import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import './App.scss';
import Header from "./view/common/Header"
import Footer from './view/common/Footer'
import BeforeLogin from "./routes/beforeLogin";
import AppRoutes from "./routes/routes"; 
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={5000} />
        <div> 
          <BeforeLogin component={Header} />
           <AppRoutes/> 
          <Footer />
        </div>
      </BrowserRouter>
    );
  }
}

export default App;

