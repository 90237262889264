import React, { useEffect, useRef, useState } from "react";
import { Tab, Nav } from 'react-bootstrap';
import { toast } from "react-toastify";
import { MasterService } from "../../services";
import { CSVLink } from "react-csv";
import Loader from "../common/loader";
import DealerlistingTable from './DealerListingTable';
import ExecutiveListingTable from './ExecutiveListing'
import { EXECUTIVE_ROLES, VISIT_EXECUTIVE_ROLES } from "../../config/constant";
const NoVisitReport = (props) => {
    const csvLink = useRef();
    const [dealersData, setDealers] = useState(null);
    const [dealersDataCount, setDealersCount] = useState(0);
    const [executiveData, setExecutiveData] = useState(null);
    const [executiveDataCount, setExecutiveCount] = useState(0);
    const [stateList, setStateList] = useState([])
    const [cityList, setCityList] = useState([])
    const [loading, setLoading] = useState(false);
    const [pageDealerCount, setPageDealerCount] = useState(0)
    const [pageExecutiveCount, setPageExecutiveCount] = useState(0)
    const [selectedTab, setSelectedTab] = useState('dealer')
    const [csvData, setCsvData] = useState([])
    const [searchData, setSearchData] = useState({})
    const [isPageReset, setPageReset] = useState(0);
    const [isDealerReset, setDealerReset] = useState(0);
    const [isExecutiveReset, setExecutiveReset] = useState(0);
    const [isPopUpReset, setPopupReset] = useState(0);
    const [roleList, setRoleList] = useState([])

    useEffect(() => {
        getDealerNoVisit(1, {})
        getExecutiveReporting(1, {}, true)
        getStateList()
        getRoleList()
        getCityList([], true)
    }, [])

    const handleSearchData = (search_data) => {
        setSearchData(search_data)
    }

    const getDealerNoVisit = async (page, searchData, page_reset) => {
        setLoading(true)
        if (page_reset) {
            setPageReset(isPageReset + 1)
        }
        let params = {sortBy: "last_visited_date", order: "DESC", "vertical_id": 1, "page": page, ...searchData }
        await MasterService.post('/sfa/dealer/getNotVisitedDealerList', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    setDealers(response.data.data.dealer_not_visited);
                    if (response.data.data.allPackageListcount && response.data.data.allPackageListcount > 0) {
                        let tempPageCount = Math.ceil(response.data.data.allPackageListcount / response.data.data.per_page);
                        setDealersCount(response.data.data.allPackageListcount)
                        setPageDealerCount(tempPageCount);
                        setLoading(false)
                    } else {
                        setDealersCount(0)
                        setPageDealerCount(0);
                        setLoading(false)
                    }
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
    }

    const getExecutiveReporting = async (page, searchData, page_reset) => {
        setLoading(true)
        if (page_reset) {
            setPageReset(isPageReset + 1)
        }
        let params = {sortBy: "bm_name", order: "DESC", "vertical_id": 1, "page": page, ...searchData }
        await MasterService.post('/sfa/dealer/getNotVisitedUserList', params)
            .then(function (response) {
                if (response.data.status === 200) {
                    setExecutiveData(response.data.data.user_not_visited);
                    if (response.data.data.allPackageListcount) {
                        let tempPageCount = Math.ceil(response.data.data.allPackageListcount / response.data.data.per_page);
                        setExecutiveCount(response.data.data.allPackageListcount)
                        setPageExecutiveCount(tempPageCount);
                    } else {
                        setExecutiveCount(0)
                        setPageExecutiveCount(0);
                    }
                    setLoading(false)
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
                setLoading(false)
            });
    }

    const getStateList = async () => {
        MasterService.post('/sfa/user/state_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    var state_list = response.data.data.map(elm => ({ id: elm.id, label: elm.state_name, value: elm.id }));
                    setStateList(state_list);
                } else {
                    toast.error(response.data.message);
                }
            })
            .catch(function (response) {
            });
    }

    const getCityList = async (state_id, state_change) => {
        if (!state_id && state_change) {
            setCityList([])
        } else {
            await MasterService.post('/sfa/user/city_list', { state_ids: [state_id.id] }).then(function (response) {
                if (response.data.status === 200) {
                    var city_list = response.data.data.map(elm => ({ id: elm.id, label: elm.city_name, value: elm.id, state_id: elm.state_id }));
                    setCityList(city_list);
                }
            }).catch(function (response) {
            });
        }
    }

    const getRoleList = async () => {
        MasterService.post('/sfa/user/role_list', {})
            .then(function (response) {
                if (response.data.status === 200) {
                    // if (props.type === "dealer") {
                        // let role_list = response.data.data.filter(e => EXECUTIVE_ROLES.includes(e.id))
                        let role_list = response.data.data.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                        setRoleList(role_list)
                    // }
                    // if (props.type === "executive") {
                    //     let role_list = response.data.data.filter(e => VISIT_EXECUTIVE_ROLES.includes(e.id))
                    //     role_list = role_list.map(elm => ({ id: elm.id, label: elm.name, value: elm.name }));
                    //     setRoleList(role_list)
                    // }
                }
            })
            .catch(function (response) {
            });
    }

    const handleClick = (value) => {
        setSelectedTab(value)
        setSearchData({})
        setPopupReset(isPopUpReset + 1)
        if (value === "dealer") {
            setDealerReset(isDealerReset + 1)
        } else {
            setExecutiveReset(isExecutiveReset + 1)
        }
    }

    return (
        <React.Fragment>
            {loading ? <Loader /> : null}
            <div className="container-fluid">
                <div className="dealer-user-mapping-user">
                    <div className="top-heading d-flex">
                        <h1>No Visit Reports</h1>
                    </div>
                    <div className="dealer-user-mapping-tab-outer no-visit-report-outer">
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <div className="dealer-user-mapping-tabs">
                                <Nav variant="pills" className="flex-column tab-line">
                                    <div className="tab-list">
                                        <Nav.Item>
                                            <Nav.Link eventKey="first" onClick={() => handleClick('dealer')}>Partner Not Visited ({dealersDataCount ? dealersDataCount : 0})</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="second" onClick={() => handleClick('executive')}>Users with 0 Visit ({executiveDataCount ? executiveDataCount : 0})</Nav.Link>
                                        </Nav.Item>
                                    </div>
                                </Nav>
                                {/* <button className="btn-line">
                                    <i className="ic-export m-xs-r"></i>
                                    <span onClick={() => downloadData()}>Export Data</span>
                                    <CSVLink
                                        data={csvData}
                                        filename={`${selectedTab}-list-${Math.round(+new Date() / 1000)}.csv`}
                                        data-interception='off'
                                        ref={csvLink}
                                    >
                                    </CSVLink>
                                </button> */}
                            </div>
                            <Tab.Content>
                                <Tab.Pane eventKey="first">
                                    <DealerlistingTable
                                        dealersData={dealersData}
                                        pageCount={pageDealerCount}
                                        isPageReset={isPageReset}
                                        stateList={stateList}
                                        cityList={cityList}
                                        isReset={isDealerReset}
                                        getDealerNoVisit={getDealerNoVisit}
                                        handleSearchData={handleSearchData}
                                        getCityList={(e) => getCityList(e, true)}
                                    />
                                </Tab.Pane>
                                <Tab.Pane eventKey="second">
                                    <ExecutiveListingTable
                                        executiveData={executiveData}
                                        pageCount={pageExecutiveCount}
                                        isPageReset={isPageReset}
                                        stateList={stateList}
                                        cityList={cityList}
                                        roleList={roleList}
                                        isReset={isExecutiveReset}
                                        isPopUpReset={isPopUpReset}
                                        getExecutiveReporting={getExecutiveReporting}
                                        getCityList={(e) => getCityList(e, true)}
                                        handleSearchData={handleSearchData}
                                    />
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}
export default NoVisitReport;