import React, { Component, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { defaultZone, EXECUTIVE_ROLES, Zone } from '../../config/constant';
import { MasterService } from '../../services';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import DateFormat from 'dateformat';
import Tooltip from 'react-tooltip-lite';
import Loader from '../common/loader';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
const { ValueContainer, Placeholder } = components;

const statusOptions = [
    { value: 'All', label: 'All' },
    { value: '1', label: 'Completed' },
    { value: '0', label: 'Pending' },
]

const ExecutiveVisitData = (props) => {
    const csvLink = useRef();
    const [visitData, setVisitData] = useState([]);
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenCustomer, setTipOpenCustomer] = useState(false);
    const [tipOpenIndex, setTipOpenIndex] = useState(0);
    const [tipOpenId, setTipOpenId] = useState(0);
    const [pageCount, setPageCount] = useState(1)
    const [offset, setOffset] = useState(0);
    const [tipOpenIdIndex, setTipOpenIdIndex] = useState(false);
    const [csvData, setCsvData] = useState([])
    const [show_popup, setPopup] = useState(false);
    const [image_url, setImageUrl] = useState(false);

    useEffect(() => {
        setVisitData(props.visitData)
        setPageCount(props.pageCount)
    }, [props.visitData, props.pageCount])

    useEffect(() => {
        setOffset(0)
    }, [props.isPageReset])

    const toggleTip = (index, state) => {
        if (state === 'close') {
            index = ''
        }
        setTipOpen(!tipOpen)
        setTipOpenIndex(index)
    }

    const showPopup = (url) => {
        setImageUrl(url)
        setPopup(true)
        document.body.classList.add("overflow-hidden");
    }

    const hidePopup = () => {
        setImageUrl("")
        setPopup(false)
        document.body.classList.remove("overflow-hidden");
    }

    const toggleTipCustomer = (user_id, index, state) => {
        if (state === 'close') {
            user_id = ''
            index = 0
        } else {
            logVisitView()
        }
        setTipOpenCustomer(!tipOpenCustomer)
        setTipOpenId(user_id)
        setTipOpenIdIndex(index)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        props.getVisitData(selectedPage + 1, {})
    };

    const downloadData = async () => {
        let csv_data = await props.getExportData('executive')
        setCsvData(csv_data)
        csvLink.current.link.click();
    }

    const logVisitView = async () => {
        let params = {
            user_id: localStorage.getItem("logged_data") ? JSON.parse(localStorage.getItem("logged_data")).user_data.user_id : ""
        }
        MasterService.post('/sfa/dealer/logVisitViewReport', params).then(function (response) {
        }).catch(function (response) {
        });
    }
    function calculateMeetingTime(checkin, checkout) {
        let difference = new Date(checkout) - new Date(checkin);
        let seconds = Math.floor(difference / 1000);
        let minutes = Math.floor(difference / 60000);
        let hours = Math.floor(minutes / 60);
        minutes = minutes % 60;
        seconds = seconds % 60;
        return `${hours}h ${minutes}m ${seconds}s`;
    }
    

    return (
        <React.Fragment>
            {props.loading ? <Loader /> : null}
            <div className='visit-report-table-outer'>
                <div className='visit-report-table-heading'>
                    <h2>Executive Listing</h2>
                    <button className="btn-line">
                        <i className="ic-export m-xs-r"></i>
                        <span onClick={() => downloadData()} >Export Data</span>
                        <CSVLink
                            data={csvData}
                            filename={`executive-visit-list-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                            ref={csvLink}
                        >
                        </CSVLink>
                    </button>
                </div>
                <div className='visit-report-table data-table bg-white radius'>
                    <table>
                        <thead>
                            <tr>
                                <th>Executive Info</th>
                                <th>Visited Type</th>
                                <th>Partner/ Bank / User</th>
                                {/* <th>City / State</th> */}
                                <th>Visited Date</th>
                                <th>Check In / Out</th>
                                <th>Meeting Time</th>
                                <th>Purpose</th>
                                <th>Remarks</th>
                                <th>Status</th>
                                <th>Next Follow-up</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitData?.length > 0 && visitData.map((element, index) => (
                                <tr>
                                    <td>
                                        <span className='d-block'>{element.visited_by + " (" + element.role_name + ")"}</span>
                                        {/* <span className='d-block'>{"SFA ID - "+element.user_id}</span> */}
                                        {/* <span>RM - {(element.reporting_manager_name ? element.reporting_manager_name : "-") + (element.reporting_manager_role_name ? " (" + element.reporting_manager_role_name + ")" : "")} </span> */}
                                    </td>
                                    <td>{element.visittype}</td>
                                    { (element.organization || element.dealer_id || element.dealer_mobile || element.bank_name || element.financer_name || element.new_partner_name || element.agent_name)? <td>
                                        {element.organization && <span className='d-block'>{element.organization}</span>}
                                        {element.dealer_id && <span className='d-block'>{"AMBK-" + element.dealer_id}</span>}
                                        {element.dealer_mobile && <span>{element.dealer_mobile}</span>}

                                        <span className='d-block'>{element.bank_name ? element.bank_name : ""}</span>
                                        <span className='d-block'>{element.financer_name ? element.financer_name : ""}</span>
                                        <span>{element.financier_region_name ? element.financier_region_name : ""}</span>
                                        {element.new_partner_name && <span className='d-block'>{element.new_partner_name ? element.new_partner_name : ""}<br/>
                                        {element.new_partner_mobile && <span>{element.new_partner_mobile}</span>}
                                            {/* <span onClick={() => toggleTipCustomer(element.user_id, index, 'open')} title="" className="tooltio-ic">
                                                <i className='ic-info-icon1 m-sm-l'></i>
                                            </span> */}
                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button"
                                                                onClick={() => toggleTipCustomer(element.user_id, index, 'close')}
                                                            ><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                <li>{element.customer_contact_email ? element.customer_contact_email : ""}</li>
                                                                <li>{element.customer_contact_number ? element.customer_contact_number : ""}</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                className="tootip-outer"
                                                tipContentClassName="tootip-outer"
                                                tagName="span"
                                                isOpen={(element.user_id === tipOpenId && index === tipOpenIdIndex) && tipOpenCustomer ? true : false}
                                                useHover={false}
                                                direction="down"
                                                forceDirection
                                            >
                                            </Tooltip>
                                        </span>
                                        }
                                        {element.agent_name && <span className='d-block'>{element.agent_name ? element.agent_name : ""}
                                            <span onClick={() => toggleTipCustomer(element.user_id, index, 'open')} title="" className="tooltio-ic">
                                                <i className='ic-info-icon1 m-sm-l'></i>
                                            </span>
                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button"
                                                                onClick={() => toggleTipCustomer(element.user_id, index, 'close')}
                                                            ><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                            {element.agent_mobile ? <li> {element.agent_mobile}</li>:""}
                                                            {element.agent_email ? <li> {element.agent_email}</li>:""}
                                                            {element.agent_address ? <li> {element.agent_address}</li>:""}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                className="tootip-outer"
                                                tipContentClassName="tootip-outer"
                                                tagName="span"
                                                isOpen={(element.user_id === tipOpenId && index === tipOpenIdIndex) && tipOpenCustomer ? true : false}
                                                useHover={false}
                                                direction="down"
                                                forceDirection
                                            >
                                            </Tooltip>
                                        </span>
                                        }
                                    </td>:
                                    <td>
                                       <ul className="user-detail">
                                            <li>{element.dealer_ship_name ? element.dealer_ship_name : "-"}</li>
                                            <li>{element.new_dealer_name ? element.new_dealer_name : "-"}</li>
                                            <li>{element.dealer_mobile_number ? element.dealer_mobile_number : "-"}</li>
                                        </ul> 
                                    </td>
}
                                    {/* <td>
                                        <span className='d-block'>{element.user_city}</span>
                                        <span>{element.user_state}</span>
                                    </td> */}

                                    <td>
                                        <span className='d-block'>{DateFormat(new Date(element.visit_checkin), 'dd mmm, yyyy')}</span>
                                    </td>
                                    <td>
                                        <span className='d-block'>{DateFormat(new Date(element.visit_checkin), 'hh:MM TT')}</span>
                                        <span>{DateFormat(new Date(element.visit_checkout), 'hh:MM TT')}</span>
                                    </td>
                                    <td><span className='d-block'>{calculateMeetingTime(element.visit_checkin, element.visit_checkout)}</span></td>
                                    <td>{element.meeting_reasons ? element.meeting_reasons : "-"}</td>
                                    {element.remarks && element.remarks != "null" && element.remarks.length > 8 ?
                                        <td>{element.remarks.substring(0, 8)}
                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button"
                                                                onClick={() => toggleTip(index, 'close')}
                                                            ><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.remarks}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                className="tootip-outer"
                                                tipContentClassName="tootip-outer"
                                                tagName="span"
                                                isOpen={(index === tipOpenIndex) && tipOpen ? true : false}
                                                useHover={false}
                                                direction="down"
                                                forceDirection
                                            >
                                                <span onClick={() => toggleTip(index, 'open')} title="">...More</span>
                                            </Tooltip>
                                        </td> : <td>{element.remarks && element.remarks != "null" ? element.remarks : "-"}</td>
                                    }
                                    <td>{element.status && statusOptions.findIndex(e => e.value == element.status) > -1 ? statusOptions.filter(e => e.value == element.status)[0].label : "-"}</td>
                                    {/* <td>{element.next_follow_up_db ? DateFormat(new Date(element.next_follow_up_db), 'dd mmm, yyyy') : "-"}</td> */}
                                    <td>
                                        <span className='d-block'>{element.next_follow_up_db ? DateFormat(new Date(element.next_follow_up_db), 'dd mmm, yyyy') : "-"}</span>
                                        <a className='view_selfie' onClick={() => showPopup(element.checkin_selfy)}>{element.checkin_selfy ? "View Selfie Pick" : ""}</a>
                                    </td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <div className='pagination-bx' id="dealerUserList">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            forcePage={offset}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                    </div >
                </div>
                <div className="vechile-document-popup model-popup-outer executive-selfie-popup">
                    <Modal show={show_popup} handleClose={hidePopup} >
                        <div className="modal-header">
                        </div>
                        <div className="modal-body">
                        <img alt='No Image' src={image_url} />
                        </div>
                    </Modal>
                </div>
            </div>


        </React.Fragment>
    )
    // }
}

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? 'modal display-block' : 'modal display-none';

    return (
        <div className={showHideClassName}>
            <section className='modal-main'>
                {children}
                <button
                    onClick={handleClose}
                    className="close_icn"
                >
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

export default ExecutiveVisitData;