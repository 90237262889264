import React, { Component, useEffect, useRef, useState } from 'react';
import Select, { components } from 'react-select';
import { toast } from 'react-toastify';
import { defaultZone, EXECUTIVE_ROLES, Zone } from '../../config/constant';
import { MasterService } from '../../services';
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes';
import DateFormat from 'dateformat';
import Tooltip from 'react-tooltip-lite';
import Loader from '../common/loader';
import ReactPaginate from 'react-paginate';
import { CSVLink } from 'react-csv';
const { ValueContainer, Placeholder } = components;

const statusOptions = [
    { value: 'All', label: 'All' },
    { value: '1', label: 'Completed' },
    { value: '0', label: 'Pending' },
]

const DealerVisitData = (props) => {
    const csvLink = useRef();
    const [visitData, setVisitData] = useState([]);
    const [tipOpen, setTipOpen] = useState(false);
    const [tipOpenIndex, setTipOpenIndex] = useState(0);
    const [pageCount, setPageCount] = useState(1)
    const [offset, setOffset] = useState(0);
    const [csvData, setCsvData] = useState([])

    useEffect(() => {
        setVisitData(props.visitData)
        setPageCount(props.pageCount)
    }, [props.visitData, props.pageCount])

    useEffect(()=> {
        setOffset(0)
    }, [props.isPageReset])


    const toggleTip = (index, state) => {
        if (state === 'close') {
            index = ''
        }
        setTipOpen(!tipOpen)
        setTipOpenIndex(index)
    }

    const handlePageClick = (e) => {
        const selectedPage = e.selected
        setOffset(selectedPage)
        props.getVisitData(selectedPage + 1, {})
    };

    const downloadData = async () => {
        let csv_data = await props.getExportData('dealer')
        setCsvData(csv_data)
        csvLink.current.link.click();
    }

    const isDate = (date) => {
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }

    const datediff = (date1, date2) => {        
        return Math.round((date2 - date1) / (1000 * 60 * 60 * 24));
    }

    return (
        <React.Fragment>
            {props.loading ? <Loader /> : null}
            <div className='visit-report-table-outer'>
                <div className='visit-report-table-heading'>
                    <h2>Partner Listing</h2>
                    <button className="btn-line">
                        <i className="ic-export m-xs-r"></i>
                        <span onClick={() => downloadData()} >Export Data</span>
                        <CSVLink
                            data={csvData}
                            filename={`dealer-visit-list-${Math.round(+new Date() / 1000)}.csv`}
                            data-interception='off'
                            ref={csvLink}
                        >
                        </CSVLink>
                    </button>
                </div>
                <div className='visit-report-table data-table bg-white radius'>
                    <table>
                        <thead>
                            <tr>
                                <th>Partner Name / Code</th>
                                <th>Visit Type</th>
                                {/* <th>City / State</th> */}
                                <th>Visited By</th>
                                <th>Visited Date</th>
                                <th>Check In / Out</th>
                                <th>Meeting Time</th>
                                <th>Purpose</th>
                                <th>Remarks</th>
                                <th>Status</th>
                                <th>Next Follow-up</th>
                            </tr>
                        </thead>
                        <tbody>
                            {visitData?.length > 0 && visitData.map((element, index) => (
                                <tr>
                                    <td>
                                        <span className='d-block'>{element.organization}</span>
                                        <span>AMBK - {element.dealer_id ? element.dealer_id : "-"} </span>
                                    </td>
                                    <td>{element.visittype ? element.visittype : "-"}</td>
                                    <td>{element.city+"/"+ element.state}</td>
                                    <td className='show_ellipsis' title={element.visited_by+" ("+element.role_name+")"}>{element.visited_by+" ("+element.role_name+")"}</td>
                                    <td>{isDate(element.visit_date)? DateFormat(new Date(element.visit_date), 'dd mmm, yyyy'):"-"}</td>
                                    <td>
                                        <span className='d-block'>{element.checkin}</span>
                                        <span>{element.checkout}</span>
                                        </td>
                                    <td><span className='d-block'>{element.meeting_reasons?element.meeting_reasons:'-'}</span></td>
                                    {element.remarks && element.remarks != "null" && element.remarks.length > 10 ?
                                        <td>{element.remarks.substring(0, 10)}
                                            <Tooltip
                                                content={(
                                                    <div className="controlled-example tooltip-main">
                                                        <div className="tooltip-data">
                                                            <span className="controlled-example_close-button"
                                                                onClick={() => toggleTip(index, 'close')}
                                                            ><i className="ic-clearclose"></i></span>
                                                            <ul className="user-detail">
                                                                {element.remarks}
                                                            </ul>
                                                        </div>
                                                    </div>
                                                )}
                                                className="tootip-outer"
                                                tipContentClassName="tootip-outer"
                                                tagName="span"
                                                isOpen={(index === tipOpenIndex) && tipOpen ? true : false}
                                                useHover={false}
                                                direction="down"
                                                forceDirection
                                            >
                                                <span onClick={() => toggleTip(index, 'open')} title="">...More</span>
                                            </Tooltip>
                                        </td> : <td>{element.remarks && element.remarks != "null" ? element.remarks : "-"}</td>
                                    }
                                    
                                    <td>{element.status && statusOptions.findIndex(e => e.value == element.status) > -1 ? statusOptions.filter(e => e.value == element.status)[0].label : "-"}</td>
                                    <td>{isDate(element.next_follow_up) ? DateFormat(new Date(element.next_follow_up), 'dd mmm, yyyy') : "-"}</td>
                                </tr>
                            ))}

                        </tbody>
                    </table>
                    <div className='pagination-bx' id="dealerUserList">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            forcePage={offset}
                            pageRangeDisplayed={5}
                            onPageChange={handlePageClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"} />
                    </div >
                </div>
            </div>


        </React.Fragment>
    )
    // }
}
export default DealerVisitData;