import React from 'react';
import { Business, Zone } from '../../config/constant';
import Loader from '../common/loader';

const ExecutiveDetail = (props) => {
    const { user_detail } = props

    return (
        <React.Fragment>
            {props.loading ? <Loader /> : null}
            {user_detail && Object.keys(user_detail).length > 0 &&
                <div className='dealer-details-outer'>
                    <h3>{user_detail.name + " (SFA ID - " + user_detail.id + ")"}</h3>
                    <ul>

                        <li>
                            <label>Business Line</label>
                            <div className='detail-list'>
                                {user_detail.business_line ?
                                    <span>{user_detail.business_line} </span>
                                    :
                                    <div>
                                        <span>UCF, UCRF, UBRF</span>
                                    </div>
                                }
                            </div>
                        </li>
                        <li>
                            <label>Role</label>
                            <div className='detail-list'>
                                <span>{user_detail.role_name ? user_detail.role_name : "-"}</span>
                            </div>
                        </li>
                        <li>
                            <label>Tagging</label>
                            <div className='detail-list'>
                                <span>{user_detail.tagging_name ? user_detail.tagging_name : "-"}</span>
                            </div>
                        </li>
                        <li>
                            <label>Created Date</label>
                            <div className='detail-list'>
                                <span>{user_detail.created_date}</span>
                            </div>
                        </li>
                        <li>
                            <label>Contact</label>
                            <div className='detail-list'>
                                <span>{user_detail.mobile}</span>
                            </div>
                        </li>
                        <li>
                            <label>Email</label>
                            <div className='detail-list'>
                                <span>{user_detail.email}</span>
                            </div>
                        </li>
                    </ul>

                </div>
            }
        </React.Fragment>
    )
}
export default ExecutiveDetail;